import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core"
import React, { useCallback, useState } from "react"

import { RouteButton } from "../../components/basic/RouteButton"
import { RouteLink } from "../../components/basic/RouteLink"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { DefaultPerPage,Pager } from "../../components/pager/Pager"
import { RouteDefinitions } from "../../components/structure/Routes"
import { FulfillmentsQuery,FulfillmentsQueryVariables,useFulfillmentsQuery } from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"
import { PagingProps } from "../../utilities/PagingProps"

export const Fulfillments: React.FC = () => {
  const {activeTenant} = useTenant()
  const [variables, setVariables] = useState<FulfillmentsQueryVariables>({
    tenantId: activeTenant.id,
    first: DefaultPerPage[0]
  })
  const {data, loading} = useFulfillmentsQuery({variables})
  const setPage = useCallback(
    (pageProps: PagingProps) => {
      setVariables({
        tenantId: activeTenant.id,
        ...pageProps
      })
    },
    [activeTenant.id]
  )

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Fulfillments"
        primaryAction={
          <RouteButton route={RouteDefinitions.createFulfillment} variant="contained" color="primary">
            Create Fulfillment
          </RouteButton>
        }
      />
      <Grid item xs={12}>
        <FulfillmentsGrid query={data}/>
        {data && 
          <Pager
            totalCount={data.fulfillments.totalCount}
            pageInfo={data.fulfillments.pageInfo}
            setPage={setPage}
            disabled={loading}
          />
        }
      </Grid>
    </Grid>
  )
}

type FulfillmentGridProps = {
  query?: FulfillmentsQuery
}

const FulfillmentsGrid: React.FC<FulfillmentGridProps> = ({query}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {query?.fulfillments.edges.map(({node}) => 
            <TableRow key={node.id}>
              <TableCell>
                <RouteLink
                  route={RouteDefinitions.viewFulfillment}
                  parameters={{id: node.id}}
                  color="inherit"
                >
                  {node.id}
                </RouteLink>
              </TableCell>
              <TableCell>{node.email}</TableCell>
              <TableCell>{node.status}</TableCell>
            </TableRow>
          )}
          {!query?.fulfillments.edges.length && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography>No fulfillments found.</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}