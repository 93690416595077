import {Grid, Paper,Table,TableBody,TableCell,TableContainer,TableHead, TableRow, Typography} from "@material-ui/core"
import React, {CSSProperties, useCallback,useState} from "react"

import { RouteButton } from "../../components/basic/RouteButton"
import { RouteLink } from "../../components/basic/RouteLink"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { DefaultPerPage,Pager } from "../../components/pager/Pager"
import { RouteDefinitions } from "../../components/structure/Routes"
import {ProductsQuery,ProductsQueryVariables,useProductsQuery} from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"
import { PagingProps } from "../../utilities/PagingProps"

export const Products: React.FC = () => {
  const {activeTenant} = useTenant()
  const [variables, setVariables] = useState<ProductsQueryVariables>({
    tenantId: activeTenant.id,
    first: DefaultPerPage[0]
  })
  const {data, loading} = useProductsQuery({variables})
  const setPage = useCallback(
    (pageProps: PagingProps) => setVariables({
      tenantId: activeTenant.id,
      ...pageProps
    }),
    [activeTenant.id]
  )
  
  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Products"
        primaryAction={
          <RouteButton route={RouteDefinitions.createProduct} variant="contained" color="primary">
            Create Product
          </RouteButton>
        }
      />
      <Grid item xs={12}>
        <ProductsGrid products={data} />
        {data && 
          <Pager 
            totalCount={data.products.totalCount}
            pageInfo={data.products.pageInfo}
            disabled={loading}
            setPage={setPage}
          />
        }
      </Grid>
    </Grid>
  )
}

const styles: {[k: string]: CSSProperties} = {
  cell: {
    whiteSpace: "nowrap"
  },
  fullWidthCell: {
    width: "99%"
  }
}

type ProductsGridProps = {
  products?: ProductsQuery
}

const ProductsGrid: React.FC<ProductsGridProps> = ({products}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={styles.fullWidthCell}>Product</TableCell>
            <TableCell style={styles.cell}>Available Licenses</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.products.edges.map(({node}) => (<ProductRow key={node.id} product={node} />))}
          {!products?.products.edges.length && (
            <TableRow>
              <TableCell colSpan={2}>
              <Typography>No products found.</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type ProductRowProps = {
  product: ProductsQuery["products"]["edges"][0]["node"]
}

const ProductRow: React.FC<ProductRowProps> = ({product}) => {
  return (
    <TableRow>
      <TableCell style={styles.fullWidthCell} component="th" scope="row">
        <RouteLink
          route={RouteDefinitions.viewProduct}
          parameters={{id: product.id!}}
          color="inherit"
        >
          {product.name}
        </RouteLink>
      </TableCell>
      <TableCell style={{...styles.cell, textAlign: "right"}}>{product.availableLicenseCount}</TableCell>
    </TableRow>
  )
}