import env from "@beam-australia/react-env"
import React from "react"
import ReactDOM from "react-dom"

import {App} from "./components/structure/App"
import { AppState,Auth0Provider } from "./providers/Auth0Provider"
import { AuthenticatedApolloProvider } from "./providers/AuthenticatedApolloProvider"
import { TenantProvider } from "./providers/TenantProvider"
import * as serviceWorker from "./serviceWorker"
import { history } from "./utilities/history"

const onRedirectCallback = (appState?: AppState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={env("AUTH0_DOMAIN")}
      client_id={env("AUTH0_CLIENT_ID")}
      redirect_uri={window.location.origin}
      audience={env("AUTH0_AUDIENCE")}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthenticatedApolloProvider>
        <TenantProvider>
          <App />
        </TenantProvider>
      </AuthenticatedApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
