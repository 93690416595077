/* eslint-disable @typescript-eslint/no-explicit-any */
// this is all based on private bullshit... may break at any time...
// https://github.com/apollographql/react-apollo/issues/562#issuecomment-517585875
export const findQueries = (manager: any, name: string): any[] => {
  const matching: any[] = []
  manager.queries.forEach((q: any) => {
    if (q.observableQuery && (q.observableQuery.queryName === name)) {
      matching.push(q)
    }
  })
  return matching
}

export const refetchQueryByName = (client: any, name: string) => {
  return Promise.all(findQueries(client.queryManager, name).map(q => q.observableQuery.refetch()))
}
