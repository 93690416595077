import {AppBar, Box,Grid, Tab, Tabs} from "@material-ui/core"
import React, { useState } from "react"

import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { useTenantQuery } from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"
import { SendGridSettings } from "./SendGridSettings"

type TabPanelProps = {
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other })  => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

const tabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const Settings: React.FC = () => {
  const {activeTenant} = useTenant()
  const {data} = useTenantQuery({
    variables: {tenantId: activeTenant.id}
  })
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Grid container spacing={3}>
      <ScreenHeader title="Settings" />
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={(_, value) => setTabIndex(value)} aria-label="simple tabs example">
        <Tab label="SendGrid" {...tabProps(0)}/>
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <Grid item xs={12}>
          {data && <SendGridSettings tenantId={activeTenant.id} sendGridConfig={data?.tenant.sendGridConfig}/>}
        </Grid>
      </TabPanel>
    </Grid>
  )
}

