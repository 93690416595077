import { Button, ButtonProps } from "@material-ui/core"
import React from "react"
import {Link as RouterLink} from "react-router-dom"


export type LinkButtonProps = {
  href: string
} & ButtonProps

export const LinkButton: React.FC<LinkButtonProps> = ({href, children, ...rest}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Button {...rest} component={RouterLink as any} to={href}>
      {children}
    </Button>
  )
}

