import React, { useMemo } from "react"

import { parseDynamicPath,RouteDefinition } from "../structure/Routes"
import { LinkButton,LinkButtonProps } from "./LinkButton"
import { PermissionGate } from "./PermissionGate"

export type RouteButtonProps = {
  route: RouteDefinition
  parameters?: Record<string, string>
} & Partial<LinkButtonProps>

export const RouteButton: React.FC<RouteButtonProps> = ({  route, parameters, children, ...rest }) => {

  const path = useMemo(() => {
    return parameters ? parseDynamicPath(route.path, parameters) : route.path
  }, [route, parameters])

  return (
    <PermissionGate permissions={route.permissions}>
      <LinkButton href={path} {...rest} >
        {children}
      </LinkButton>
    </PermissionGate>
  )
}
