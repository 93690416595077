import { Button,createStyles,Grid,IconButton,List,ListItem, ListItemSecondaryAction,ListItemText,makeStyles, Modal,TextField, Theme, Typography } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/HighlightOff"
import React, { Dispatch, useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { PaperGrid } from "../../components/basic/PaperGrid"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { ProductSelector } from "../../components/product-selector/ProductSelector"
import { RouteDefinitions } from "../../components/structure/Routes"
import {ProductsQuery,useCreateFulfillmentMutation} from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1, 0),
      }
    },
    paperModal: {
      position: "absolute",
      width: 400,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
    },
    paper: {
      backgroundColor: theme.palette.background.default
    },
    quantity: {
      marginRight: theme.spacing(3),
      width: 100
    },
    sectionHeader: {
      display: "flex",
      justifyContent: "space-between"
    }
  })
)
type FulfillmentProduct = [ProductsQuery["products"]["edges"][0]["node"], number]

type FormData = {
  email: string
}

export const CreateFulfillment: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const {activeTenant} = useTenant()
  const {register, handleSubmit, errors, formState: {isValid}} = useForm<FormData>()
  const [create, {error, loading}] = useCreateFulfillmentMutation()
  const [selectedProducts, setSelectedProducts] = useState<FulfillmentProduct[]>([])
  const onSubmit = useCallback(async ({email}: FormData) => {
      if (!loading && isValid && selectedProducts.length) {
        await create({
          variables: {
            input: {
              email,
              products: selectedProducts.map(([product, count]) => ({productId: product.id!, count}))
            }, 
            tenantId: activeTenant.id
          },
          refetchQueries: ["fulfillments"]
        })
        history.goBack()
      }
    }, [activeTenant.id, create, history, isValid, loading, selectedProducts])

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Create Fulfillment"
        breadcrumbs={[
          { href: RouteDefinitions.fulfillments.path, content: "Fulfillments"}
        ]}
      />
      <PaperGrid item xs={12}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <TextField
            name="email"
            label="Email"
            inputRef={register({
              required: "Email is required.",
              pattern: {
                value: /^[A-Z!._%+-]+@(?:[A-Z0-9]+(?:-[A-Z0-9]+)*\.)+[A-Z]{2,}$/i,
                message: "Please enter a valid email address"
              }
            })}
            fullWidth
            type="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <ProductList
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          <Button type="submit" disabled={loading || !isValid || !selectedProducts.length} variant="contained" color="primary">Create</Button>
        </form>
        { error && <Typography>{error.message}</Typography>}
      </PaperGrid>
    </Grid>
  )
}


type ProductListProps = {
  selectedProducts: FulfillmentProduct[]
  setSelectedProducts: Dispatch<FulfillmentProduct[]>
}

const ProductList: React.FC<ProductListProps> = ({selectedProducts, setSelectedProducts}) => {
  const classes = useStyles()
  const [showAdd, setShowAdd] = useState(false)
  
  return (
    <div>
      <Modal open={showAdd} onClose={() => setShowAdd(false)}>
        <div className={classes.paperModal}>
          <ProductSelector
            onSelect={products => {
              const newProducts: FulfillmentProduct[] = products.map(p => [p, 1])
              setSelectedProducts([...selectedProducts, ...newProducts])
              setShowAdd(false)
            }}
            excludeIds={selectedProducts.map(([p, _]) => p.id!)}
          />
        </div>
      </Modal>
      <Grid container>
        <Grid item xs={12} className={classes.sectionHeader}>
          <Typography>Products</Typography>
          <IconButton aria-label="Add Product" onClick={() => setShowAdd(true)}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <List className={classes.paper}>
            {!selectedProducts.length
              ? (
                <ListItem>
                  <ListItemText primary="Please select a product."/>
                </ListItem>
              )
              : selectedProducts.map(([product, qty], index) => {
                const remainingLicenses = product.availableLicenseCount! - qty
                return (
                  <ListItem key={product.id!}>
                    <ListItemText primary={product.name} secondary={`${remainingLicenses} license${remainingLicenses === 1 ? "" : "s"} left`}/>
                    <TextField
                      type="number"
                      value={qty}
                      className={classes.quantity}
                      inputProps={{min: 1, max: product.availableLicenseCount!}}
                      onChange={(e) => {
                        const v = parseInt(e.target.value)
                        const sp = [...selectedProducts]
                        sp[index][1] = v
                        setSelectedProducts(sp)
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          setSelectedProducts(selectedProducts.filter(([p, _]) => p.id! !== product.id!))
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
            
          </List>
        </Grid>
      </Grid>
    </div>
  )
}

