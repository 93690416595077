import { Button,createStyles,Dialog, DialogActions,DialogContent,DialogContentText, DialogTitle, Grid, makeStyles, Modal,ModalProps, TextField,Theme, Typography } from "@material-ui/core"
import React, { Dispatch,useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory,useParams } from "react-router-dom"
import { ApiScopes } from "shared"

import { PaperGrid } from "../../components/basic/PaperGrid"
import { PermissionGate } from "../../components/basic/PermissionGate"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { EmailTemplateForm } from "../../components/email-template-form/EmailTemplateForm"
import { ProductSelector } from "../../components/product-selector/ProductSelector"
import { RouteDefinitions } from "../../components/structure/Routes"
import { useDeleteEmailTemplateMutation, useEmailTemplateQuery, useSendPreviewEmailLazyQuery,useUpdateEmailTemplateMutation } from "../../graphql"
import { usePermissions } from "../../hooks/use-permissions"
import { useTenant } from "../../providers/TenantProvider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperModal: {
      position: "absolute",
      width: 400,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
    }
  })
)

export const EmailTemplate: React.FC = () => {
  const canEditOrDelete = usePermissions([ApiScopes.AdminTemplates])
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [sendPreviewOpen, setSendPreviewOpen] = useState(false)
  const history = useHistory()
  const {activeTenant} = useTenant()
  const {id} = useParams<{id: string}>()
  const {data} = useEmailTemplateQuery({
    variables: {
      id,
      tenantId: activeTenant.id
    }
  })
  const [deleteEmailTemplate, {loading: deleteLoading}] = useDeleteEmailTemplateMutation()

  const onDelete = useCallback( async () => {
    if (!deleteLoading && canEditOrDelete) {
      await deleteEmailTemplate({
        variables: {
          id,
          tenantId: activeTenant.id
        },
        refetchQueries: ["emailTemplates"]
      })

      history.goBack()
    }
  }, [activeTenant.id, canEditOrDelete, deleteEmailTemplate, deleteLoading, history, id])

  const initialFormValues = useMemo(() => {
    if (data?.emailTemplate) {
      const {name, subject, template} = data.emailTemplate
      return {
        name,
        subject,
        template
      }
    }
    return undefined
  }, [data])

  const [updateEmailTemplate, {loading}] = useUpdateEmailTemplateMutation()
  const onSubmit = useCallback( async ({name, subject, template}) => {
    if (!loading && canEditOrDelete) {
      await updateEmailTemplate({
        variables: {
          id,
          tenantId: activeTenant.id,
          input: {name, subject, template}
        },
        refetchQueries: ["emailTemplates", "emailTemplate"]
      })
      history.goBack()
    }
  }, [loading, canEditOrDelete, updateEmailTemplate, id, activeTenant.id, history])

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title={data?.emailTemplate.name || "..."}
        breadcrumbs={[
          { href: RouteDefinitions.emailTemplates.path, content: "Email Templates"}
        ]}
        primaryAction={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSendPreviewOpen(true)}
          >
            Send Preview Email
          </Button>
        }
      />
      <SendPreviewModal
        open={sendPreviewOpen}
        onClose={() => setSendPreviewOpen(false)}
      />
      <PaperGrid item xs={12}>
        <EmailTemplateForm
          submitText="Save"
          disabled={!canEditOrDelete || loading}
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          footer={
            <PermissionGate permissions={[ApiScopes.AdminTemplates]}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setDeleteOpen(true)}
              >
                Delete
              </Button>
              <DeleteDialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                onDelete={() => onDelete()}
              />
            </PermissionGate>
          }
        />
      </PaperGrid>
    </Grid>
  )
}

type DeleteDialogProps = {
  open: boolean
  onClose: Dispatch<void>
  onDelete: Dispatch<void>
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, onClose, onDelete }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>Delete email template?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this email template?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={() => onDelete()} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type SendPreviewFormData = {
  to: string
}

type SendPreviewModalProps = {

  onClose: Dispatch<void>
} & Omit<ModalProps, "children" | "onClose">

const SendPreviewModal: React.FC<SendPreviewModalProps> = ({onClose, ...props}) => {
  const classes = useStyles()
  const {activeTenant} = useTenant()
  const {register, handleSubmit, errors} = useForm<SendPreviewFormData>()
  const [selectedProductId, setSelectedProductId] = useState<string>()
  const [sendPreviewEmail, {loading}] = useSendPreviewEmailLazyQuery()
  const {id} = useParams<{id: string}>()

  const onSubmit = useCallback(async ({to}: SendPreviewFormData) => {
    console.log("STUFF: ", {loading, selectedProductId, to})
      if (!loading && selectedProductId) {
        await sendPreviewEmail({
          variables: {
            id,
            to,
            productId: selectedProductId,
            tenantId: activeTenant.id
          }
        })
        onClose()
      }
    }, [activeTenant.id, selectedProductId, loading, sendPreviewEmail, id, onClose])

  return (
    <Modal {...props} onClose={() => onClose()}>
      <Grid container spacing={3} className={classes.paperModal}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" gutterBottom>Send Preview Email</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="to"
              label="To"
              inputRef={register({
                required: "To is required.",
                pattern: {
                  value: /^[A-Z!._%+-]+@(?:[A-Z0-9]+(?:-[A-Z0-9]+)*\.)+[A-Z]{2,}$/i,
                  message: "Please enter a valid email address"
                }
              })}
              fullWidth
              type="email"
              error={!!errors.to}
              helperText={errors.to?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Select a Product</Typography>
            <ProductSelector
              onSelect={(products) => {
                if (!products.length) {
                  setSelectedProductId(undefined)
                } else {
                  const {id} = products[0]
                  setSelectedProductId(id)
                }
              }}
              allowNoAvailableLicenseSelection
              singleSelect
              autoSelect
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" disabled={loading || !selectedProductId || !!errors.to} variant="contained" color="primary">Send Preview</Button>
          </Grid>
        </form>
      </Grid>
    </Modal>
  )
}
