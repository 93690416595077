import env from "@beam-australia/react-env"
import {Grid, Typography} from "@material-ui/core"
import React, { useEffect,useMemo,useState } from "react"

import { PaperGrid } from "../../components/basic/PaperGrid"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { useMeQuery } from "../../graphql"
import { useAuth0 } from "../../providers/Auth0Provider"
import { useTenant } from "../../providers/TenantProvider"

type CodeProps = {
  title?: string
}

const Code: React.FC<CodeProps> = ({ title, children }) => {
  return (
    <PaperGrid item xs={12}>
      {title && <Typography variant="body2" color="textSecondary" align="center">{title}</Typography>}
      <pre style={{
        whiteSpace: "pre-wrap",
        wordWrap: "break-word"
      }}>
        {children}
      </pre>
    </PaperGrid>
  )
}

export const Dashboard = () => {
  const { isAuthenticated, token, getIdTokenClaims, permissions } = useAuth0()
  const {data} = useMeQuery()
  const user = useMemo(() => data?.me, [data])

  const [claims, setClaims] = useState<IdToken>()
  const {tenants, activeTenant} = useTenant()
  useEffect(() => {
    if (isAuthenticated) {
      (async() => {
        setClaims(await getIdTokenClaims({
          audience: env("AUTH0_AUDIENCE"), 
          scope: env("AUTH0_SCOPE")
        }))
      })()
    }
  }, [isAuthenticated, getIdTokenClaims])

  return (
    <Grid container spacing={3}>
      <ScreenHeader title="Dashboard" />
      <Code title="Token">{token}</Code>
      <Code title="Active Tenant">{JSON.stringify(activeTenant, null, 2)}</Code>
      <Code title="My Tenants">{JSON.stringify(tenants, null, 2)}</Code>
      <Code title="Permissions">{JSON.stringify(permissions, null, 2)}</Code>
      <Code title="User">{JSON.stringify(user, null, 2)}</Code>
      <Code title="Claims">{JSON.stringify(claims, null, 2)}</Code>
    </Grid>
  )
}