import { Grid } from "@material-ui/core"
import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"

import { PaperGrid } from "../../components/basic/PaperGrid"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { EmailTemplateForm } from "../../components/email-template-form/EmailTemplateForm"
import { RouteDefinitions } from "../../components/structure/Routes"
import { useCreateEmailTemplateMutation } from "../../graphql"
import { usePermissions } from "../../hooks/use-permissions"
import { useTenant } from "../../providers/TenantProvider"

export const CreateEmailTemplate: React.FC = () => {
  const canCreate = usePermissions(RouteDefinitions.createEmailTemplate.permissions)
  const history = useHistory()
  const {activeTenant} = useTenant()

  const [createEmailTemplate, {loading}] = useCreateEmailTemplateMutation()
  const onSubmit = useCallback( async ({name, subject, template}) => {
    if (!loading && canCreate) {
      await createEmailTemplate({
        variables: {
          tenantId: activeTenant.id,
          input: {name, subject, template}
        },
        refetchQueries: ["emailTemplates"]
      })
      history.goBack()
    }
  }, [loading, canCreate, createEmailTemplate, activeTenant.id, history])

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Create"
        breadcrumbs={[
          { href: RouteDefinitions.emailTemplates.path, content: "Email Templates"}
        ]}
      />
      <PaperGrid item xs={12}>
        <EmailTemplateForm
          submitText="Create"
          disabled={!canCreate || loading}
          onSubmit={onSubmit}
        />
      </PaperGrid>
    </Grid>
  )
}