import { useApolloClient } from "@apollo/react-hooks"
import {Button,createStyles,Grid, IconButton,List, ListItem,ListItemSecondaryAction, ListItemText, makeStyles, Modal,Paper, Popover,Table, TableBody,TableCell, TableContainer, TableHead, TableRow, TextField,Theme, Tooltip,Typography} from "@material-ui/core"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import React, { Dispatch,useCallback,useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import {ApiScopes,groupBy} from "shared"

import { PermissionGate } from "../../components/basic/PermissionGate"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { RouteDefinitions } from "../../components/structure/Routes"
import {FulfillmentLineItem,Product, useFulfillmentQuery,useReplaceLineItemMutation,useRevealKeyLazyQuery} from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"
import { refetchQueryByName } from "../../utilities/refech-queries"

// TODO i don't want to do this, use Partial that is...  how do
// we reach into GetFulfillmentQuery and get the component types?

export type DeepPartial<T> = T extends Function ? T : (T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T);

type LineItem = DeepPartial<FulfillmentLineItem>

type LineItemGroup = {
  product: Partial<Product>
  lineItems: LineItem[]
}

export const Fulfillment: React.FC = () => {
  const {activeTenant} = useTenant()
  const {id} = useParams<{id: string}>()
  const {data, loading, error} = useFulfillmentQuery({variables:{ id, tenantId: activeTenant.id}})
  const [byProduct, setByProduct] = useState<LineItemGroup[]>()

  useEffect(
    () => {
      if (data) {
        setByProduct(
          Object
            .entries(groupBy(data.fulfillment.lineItems, _ => _.licenseKey.product.id))
            .map(([productId, [fli, ...rest]]) => ({
              product: fli.licenseKey.product, 
              lineItems: [fli, ...rest]
            })))
      }
    },
    [data]
  )

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Fulfillment"
        breadcrumbs={[
          { href: RouteDefinitions.fulfillments.path, content: "Fulfillments"}
        ]}
        secondaryActions={[
          {
            text: "View Activity",
            route: RouteDefinitions.viewFulfillmentActivity,
            parameters: { id }
          }
        ]}
      />
      <Grid item xs={12}>
        {loading && <Typography>loading...</Typography>}
        {error && <Typography>error: {error.message}</Typography>}
        {byProduct && id && 
          <ProductsGrid
            products={byProduct} 
            tenantId={activeTenant.id}
            fulfillmentId={id}
          />
        }
      </Grid>
    </Grid>
  )
}

type ProductsGridProps = {
  products: LineItemGroup[]
  tenantId: string
  fulfillmentId: string
}
const ProductsGrid: React.FC<ProductsGridProps> = ({products, fulfillmentId, tenantId}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(p => <ProductRow key={`product-row-${p.product.id}`} node={p} fulfillmentId={fulfillmentId} tenantId={tenantId} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type ProductRowProps = {
  node: LineItemGroup
  fulfillmentId: string
  tenantId: string
}

const ProductRow: React.FC<ProductRowProps> = ({node: {product, lineItems}, fulfillmentId, tenantId}) => {
  return (
    <TableRow key={product.id}>
      <TableCell>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {product.name}
          </Grid>
          <Grid item xs={12}>
            <LineItems lineItems={lineItems} fulfillmentId={fulfillmentId} tenantId={tenantId} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>{lineItems.length}</TableCell>
    </TableRow>
  )
}

type LineItemsProps = {
  lineItems: LineItem[]
  fulfillmentId: string
  tenantId: string
}

const LineItems: React.FC<LineItemsProps> = ({lineItems, fulfillmentId, tenantId}) => {
  const classes = useStyles()
  const [replaceMutation] = useReplaceLineItemMutation()
  const replace = useCallback(
     async (lineItemId: string, reason: string) => {
        await replaceMutation({
          variables: {fulfillmentId, lineItemId, reason, tenantId},
          refetchQueries: ["fulfillment", "auditLogs"],
          awaitRefetchQueries: true
        })
    },
    [fulfillmentId, replaceMutation, tenantId]
  )

  return (
    <List className={classes.lineItemContainer}>
      {lineItems.map(({id, status, replacementReason, licenseKey}) => (
        <ListItem key={`line-item-${id}`}>
          <ListItemText
            primary={id}
            secondary={
              <Status
                status={status || "unknown"} 
                replacementReason={replacementReason}
              />
            }
          />
          <ListItemSecondaryAction className={classes.multipleListActions}>
              {status === "ACTIVE" && 
                <PermissionGate permissions={[ApiScopes.RevealFulfillments]}>
                  <KeyRevealer fulfillmentId={fulfillmentId} lineItemId={id!}/>
                </PermissionGate>
              }
              {status !== "REPLACED" &&
                <Replacer replace={(reason: string) => replace(id!, reason)}/>
              }
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

type StatusProps = {
  status: string
  replacementReason?: string | null
}

const Status: React.FC<StatusProps> = ({status, replacementReason}) => {
  return status === "REPLACED" 
    ? <Tooltip title={replacementReason||""} placement="bottom-start"><Typography>{status}</Typography></Tooltip>
    : <Typography>{status}</Typography>
}

type ReplacerProps = {
  replace: Dispatch<string>
}

const Replacer: React.FC<ReplacerProps> = ({replace}) => {
  type FormData = {
    reason: string
  }
  const classes = useStyles(0)
  const {register, handleSubmit, errors} = useForm<FormData>()
  const onSubmit = useCallback(({reason}) => {
      replace(reason)
  }, [replace])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement|null>(null)
  return (
    <div>
      <Button
        variant="contained" 
        color="secondary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Replace
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={classes.popoverForm}>
          <TextField name="reason" placeholder="Reason..." inputRef={register({required: "Reason is required."})} error={!!errors.reason} helperText={errors.reason?.message} />
          <IconButton type="submit"><DoneOutlineIcon fontSize="small"/></IconButton>
          <IconButton onClick={() => setAnchorEl(null)}><CloseOutlinedIcon fontSize="small"/></IconButton>
        </form>
      </Popover>
    </div>
  )
}

type KeyRevealerProps = {
  fulfillmentId: string
  lineItemId: string
}
const KeyRevealer: React.FC<KeyRevealerProps> = ({fulfillmentId, lineItemId}) => {
  const {paperModal} = useStyles()
  const {activeTenant} = useTenant()
  const client = useApolloClient()
  const [get, {data, loading, error}] = useRevealKeyLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: async () => {
      await refetchQueryByName(client, "auditLogs")
    }
  })
  const [showModal, setShowModal] = useState(false)

  const reveal = useCallback(
    () => {
      setShowModal(true)
      get({
        variables: {
          fulfillmentId,
          lineItemId,
          tenantId: activeTenant.id
        }
      })
    }, 
    [activeTenant.id, fulfillmentId, get, lineItemId]
  )

  return (
    <div>
      <Button variant="contained" color="primary" onClick={reveal}>Reveal</Button>
      <Modal open={showModal}>
        <div className={paperModal}>
          {data && <Typography>{data?.revealKey}</Typography>}
          {error && <Typography>{error?.message}</Typography>}
          {loading && <Typography>loading...</Typography>}
          <Button onClick={() => setShowModal(false)}><Typography>close</Typography></Button>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperModal: {
      position: "absolute",
      width: 400,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    lineItemContainer: {
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[1]
    },
    multipleListActions: {
      display: "flex",
      "& > *": {
        marginLeft: theme.spacing(2)
      }
    },
    popoverForm: {
      paddingLeft: theme.spacing(1),
      "& > *": {
        margin: theme.spacing(1)
      }
    }
  }),
)
