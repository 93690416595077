import { Button,Grid, TextField } from "@material-ui/core"
import React, { useCallback,useEffect } from "react"
import { OnSubmit, useForm } from "react-hook-form"

import { usePrevious } from "../../hooks/use-previous"
import { HtmlEditor } from "../html-editor/HtmlEditor"

export type EmailTemplateFormData = {
  name: string
  subject: string
  template: string
}

export type EmailTemplateFormProps = {
  submitText: string
  disabled: boolean
  submitDisabled?: boolean
  onSubmit: OnSubmit<EmailTemplateFormData>
  initialValues?: EmailTemplateFormData
  footer?: React.ReactNode
}

export const EmailTemplateForm: React.FC<EmailTemplateFormProps> = ({ submitText, disabled, submitDisabled, onSubmit, initialValues, footer }) => {
  const { register, handleSubmit, reset, setValue, errors, setError, clearError, watch, formState: {dirty} } = useForm<EmailTemplateFormData>()
  const watchTemplate = watch("template")
  const previousWatchTemplate = usePrevious(watchTemplate)

  const validateTemplate = useCallback((template: string) => {
    if (!template) {
      setError("template", "required", "Template is required.")
    } else {
      clearError("template")
    }
    return !!template
  }, [setError, clearError])

  useEffect(() => {
    if (watchTemplate !== previousWatchTemplate) {
      validateTemplate(watchTemplate)
    }
  }, [previousWatchTemplate, validateTemplate, watchTemplate])

  useEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  useEffect(() => {
    register({ name: "template" })
  }, [register])

  const submit = useCallback(async (data: EmailTemplateFormData) => {
    if (validateTemplate(data.template)) {
      await onSubmit(data)
    }
  }, [onSubmit, validateTemplate])

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Name"
            inputRef={register({required: "Name is required." })}
            disabled={disabled}
            InputLabelProps={{shrink: true}}
            error={!!errors.name}
            helperText={errors.name?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="subject"
            label="Subject"
            inputRef={register({required: "Subject is required." })}
            disabled={disabled}
            InputLabelProps={{shrink: true}}
            error={!!errors.subject}
            helperText={errors.subject?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <HtmlEditor
            defaultValue={initialValues?.template}
            label="Template"
            controls={["title", "bold", "italic", "underline", "link", "numberList",
            "bulletList", "quote", "code", "clear", "media",
            "strikethrough", "highlight", "atomic"]}
            onChangeDebounceDelay={500}
            onChange={v => setValue("template", v)}
            disabled={disabled}
            error={!!errors.template}
            helperText={errors.template?.message}
          />
        </Grid>
        <Grid item container xs={12} justify="space-between">
          <Button type="submit" disabled={!dirty || submitDisabled || disabled} variant="contained" color="primary">{submitText}</Button>
          {footer}
        </Grid>
      </Grid>
    </form>
  )
}