import {List, ListItem, ListItemIcon, ListItemText, Tooltip} from "@material-ui/core"
import DashboardIcon from "@material-ui/icons/Dashboard"
import EmailTemplatesIcon from "@material-ui/icons/Drafts"
import FulfillmentIcon from "@material-ui/icons/List"
import ProductsIcon from "@material-ui/icons/LocalOffer"
import ActivityIcon from "@material-ui/icons/Policy"
import SettingsIcon from "@material-ui/icons/Settings"
import React from "react"
import {Link as RouterLink, useLocation} from "react-router-dom"

import { PermissionGate } from "../basic/PermissionGate"
import {RouteDefinitions} from "../structure/Routes"

type NavBarListItemProps = {
  href: string
  title: string
  icon: React.ReactNode
}

const NavBarListItem: React.FC<NavBarListItemProps> = ({href, title, icon}) => {
  const {pathname} = useLocation()
  
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <ListItem button component={RouterLink as any} to={href} selected={pathname === href || (href !== "/" && pathname.startsWith(href))}>
      <Tooltip title={title}>
        <ListItemIcon aria-label={title}>
          {icon}
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={title} />
    </ListItem>
  )
}

export const NavBar: React.FC = () => {
  return (
    <List>
      <NavBarListItem href="/" title="Dashboard" icon={<DashboardIcon />} />
      <PermissionGate permissions={RouteDefinitions.products.permissions}>
        <NavBarListItem href={RouteDefinitions.products.path} title="Products" icon={<ProductsIcon />} />
      </PermissionGate>
      <PermissionGate permissions={RouteDefinitions.fulfillments.permissions}>
        <NavBarListItem href={RouteDefinitions.fulfillments.path} title="Fulfillments" icon={<FulfillmentIcon />} />
      </PermissionGate>
      <PermissionGate permissions={RouteDefinitions.emailTemplates.permissions}>
        <NavBarListItem href={RouteDefinitions.emailTemplates.path} title="Email Templates" icon={<EmailTemplatesIcon />} />
      </PermissionGate>
      <PermissionGate permissions={RouteDefinitions.activity.permissions}>
        <NavBarListItem href={RouteDefinitions.activity.path} title="Activity" icon={<ActivityIcon />} />
      </PermissionGate>
      <PermissionGate permissions={RouteDefinitions.settings.permissions}>
        <NavBarListItem href={RouteDefinitions.settings.path} title="Settings" icon={<SettingsIcon />} />
      </PermissionGate>
    </List>
  )
}