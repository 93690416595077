import {useMemo} from "react"

import { useAuth0 } from "../providers/Auth0Provider"

export const usePermissions = (requiredPermissions?: string[]) => {
  const {permissions} = useAuth0()
  return useMemo(() => {
    if (!requiredPermissions?.length) return true
    return  !!permissions?.length && requiredPermissions.some(p => permissions.includes(p))
  }, [permissions, requiredPermissions])
}