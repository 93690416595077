import {Breadcrumbs, Grid,IconButton,ListItemIcon, ListItemText,MenuItem, Typography} from "@material-ui/core"
import SecondaryActionsIcon from "@material-ui/icons/MoreVert"
import React, { Dispatch,useMemo,useRef, useState } from "react"
import {Link as RouterLink} from "react-router-dom"

import { usePermissions } from "../../hooks/use-permissions"
import { DropdownMenu } from "../dropdown-menu/DropdownMenu"
import { parseDynamicPath,RouteDefinition } from "../structure/Routes"
import { Link } from "./Link"
import { PermissionGate } from "./PermissionGate"

export type SecondaryAction = {
  text: string
  icon?: React.ReactNode
  onClick?: Dispatch<void>
  permissions?: string[]
  route?: RouteDefinition
  parameters?: Record<string, string>
}

export type Breadcrumb = {
  href: string
  content: React.ReactNode
}

export type ScreenHeaderProps = {
  title: string
  breadcrumbs?: Breadcrumb[]
  primaryAction?: React.ReactNode
  secondaryActions?: SecondaryAction[]
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = ({ title, breadcrumbs, primaryAction, secondaryActions }) => {
  const secondaryAnchor = useRef(null)
  const [secondaryMenuOpen, setSecondaryMenuOpen] = useState(false)
  const allSecondaryPermissions = useMemo(() => {
    if (!secondaryActions) return []
    return secondaryActions
      .reduce((all, actions) => {
        (all || []).push(...(actions.route?.permissions || actions.permissions || []))
        return all
      }, [] as string[])
  }, [secondaryActions])
  const hasAnySecondaryPermission = usePermissions(allSecondaryPermissions)

  return (
    <Grid item container xs={12} sm spacing={1}>
      <Grid item xs>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs?.map(({href, content}, index) => (
            <Link key={`breadcrumb-${index}`} color="inherit" href={href}>{content}</Link>
          ))}
          <Typography color="textPrimary">{title}</Typography>
        </Breadcrumbs>
      </Grid>
      {primaryAction && (
        <Grid item>
          {primaryAction}
        </Grid>
      )}
      {secondaryActions && hasAnySecondaryPermission && (
        <Grid item>
          <IconButton size="small" ref={secondaryAnchor} onClick={() => setSecondaryMenuOpen(true)}>
            <SecondaryActionsIcon />
          </IconButton>
          <DropdownMenu
            anchorRef={secondaryAnchor}
            open={secondaryMenuOpen}
            onClose={() => setSecondaryMenuOpen(false)}
          >
            {secondaryActions.map((action, index) => (
              <SecondaryActionItem key={`screen-header-secondary-action-${index}`} {...{...action, onClick: () => {
                setSecondaryMenuOpen(false)
                if (action.onClick) {
                  action.onClick()
                }
              }}}/>
            ))}
          </DropdownMenu>
        </Grid>
      )}
    </Grid>
  )
}

type SecondaryActionItemProps = {
  onClick: Dispatch<void>
} & SecondaryAction

const SecondaryActionItem: React.FC<SecondaryActionItemProps> = ({ text, icon, onClick, permissions, route, parameters }) => {
  const path = useMemo(() => {
    if (!route) {
      return undefined
    }
    return parameters ? parseDynamicPath(route.path, parameters) : route.path
  }, [route, parameters])

  if (route) {
    return (
      <PermissionGate permissions={route.permissions}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <MenuItem button component={RouterLink as any} to={path}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={text} />
        </MenuItem>
      </PermissionGate>
    )
  }
  
  return (
    <PermissionGate permissions={permissions}>
      <MenuItem button onClick={() => onClick()}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} />
      </MenuItem>
    </PermissionGate>
  )
}