import {AppBar, Avatar, AvatarProps,Divider, IconButton, List,ListItem,ListItemIcon, ListItemText,Popover,PopoverProps,Toolbar, Typography} from "@material-ui/core"
import LogoutIcon from "@material-ui/icons/ExitToApp"
import MenuIcon from "@material-ui/icons/Menu"
import clsx from "clsx"
import React, { useMemo,useRef, useState } from "react"

import {MeQuery, useMeQuery} from "../../graphql"
import { useAuth0 } from "../../providers/Auth0Provider"
import { TenantSelector } from "../tenant-selector/TenantSelector"
import {useStyles} from "./styles"

type User = MeQuery["me"]

type UserAvatarProps = {
  user: User
} & AvatarProps

const UserAvatar: React.FC<UserAvatarProps> = ({user, ...props}) => <Avatar alt={user.name || undefined} src={user.picture || undefined} {...props}>{user.name}</Avatar>

type UserMenuProps = {
  user: User
  onClose: () => void
} & PopoverProps

const UserMenu: React.FC<UserMenuProps> = ({onClose, user, ...props}) => {
  const classes = useStyles()
  const { logout } = useAuth0()

  return (
    <Popover
      onClose={onClose}
      {...props}
    >
      <List>
        <ListItem>
          <UserAvatar user={user} className={classes.largeAvatar} style={{marginLeft: 10, marginRight: 10, marginBottom: 10}} />
          <div style={{margin: 10}}>
            <Typography>{user.name}</Typography>
            <TenantSelector style={{width: "100%"}} />
          </div>
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => {
            onClose()
            logout()
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Popover>
  )
}

export type HeaderProps = {
  onDrawerOpen: () => void
  drawerOpen: boolean
}

export const Header: React.FC<HeaderProps> = ({onDrawerOpen, drawerOpen}) => {
  const classes = useStyles()
  const {data} = useMeQuery()
  const user = useMemo(() => data?.me, [data])

  const avatarRef = useRef(null)
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLButtonElement | null>(null)
  
  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuAnchorEl((prevEl) => prevEl ? null : event.currentTarget)
  }

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerOpen}
          className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          Stoploss
        </Typography>
        {user &&
          <>
            <IconButton ref={avatarRef} color="inherit" onClick={handleToggle}>
              <UserAvatar user={user} />
            </IconButton>
            <UserMenu
              user={user}
              anchorEl={userMenuAnchorEl}
              open={!!userMenuAnchorEl}
              onClose={() => setUserMenuAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
          </>
        }
      </Toolbar>
    </AppBar>
  )
}