import createAuth0Client from "@auth0/auth0-spa-js"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import JwtDecode from "jwt-decode"
import React, { useCallback,useContext, useEffect, useState } from "react"

import { Splash } from "../screens/splash/Splash"

const DEFAULT_REDIRECT_CALLBACK: OnRedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

type TokenPayload = {
  permissions?: string[]
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppState = any
type OnRedirectCallback = (appState?: AppState) => void

type User = {
  email: string
  email_verified: boolean
  given_name: string
  family_name: string
  nickname: string
  name: string
  picture: string
  locale: string
  updated_at?: Date
  sub: string
}

type Context = {
  isAuthenticated?: boolean
  loading: boolean
  popupOpen: boolean
  token?: string
  permissions?: string[]
  loginWithPopup: (params?: PopupLoginOptions) => Promise<void>
  handleRedirectCallback: () => Promise<void>
  getIdTokenClaims: (options?: getIdTokenClaimsOptions) => Promise<IdToken>
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string | undefined>
  getTokenWithPopup: (options?: GetTokenWithPopupOptions) => Promise<string>
  logout: (options?: LogoutOptions) => void
}

export const Auth0Context = React.createContext<Context | null>(null)
export const useAuth0 = () => useContext(Auth0Context)!

type Auth0ProviderProps = {
  onRedirectCallback: OnRedirectCallback
} & Auth0ClientOptions

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [token, setToken] = useState<string>()
  const [permissions, setPermissions] = useState<string[]>()

  // redirect user to login if they are not logged in
  // basically forcing login here
  useEffect(() => {
    if (loading || isAuthenticated || !auth0Client) {
      return
    }
    (async () => {
      await auth0Client.loginWithRedirect({
        appState: {targetUrl: window.location.pathname}
      })
    })()
  }, [loading, isAuthenticated, auth0Client])

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes("code=") &&
          window.location.search.includes("state=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    (async() => {
      setToken(auth0Client ? await auth0Client.getTokenSilently() : undefined)
    })()
  }, [auth0Client])

  useEffect(() => {
    if (token) {
      const payload = JwtDecode<TokenPayload>(token)
      setPermissions(payload?.permissions)
    } else {
      setPermissions(undefined)
    }
  }, [token])

  const loginWithPopup = useCallback(async (params: PopupLoginOptions = {}) => {
    if (auth0Client) {
      setPopupOpen(true)
      try {
        await auth0Client.loginWithPopup(params)
      } catch (error) {
        console.error(error)
      } finally {
        setPopupOpen(false)
      }
      setIsAuthenticated(true)
    }
  }, [auth0Client])

  const handleRedirectCallback = useCallback(async () => {
    if (auth0Client) {
      setLoading(true)
      await auth0Client.handleRedirectCallback()
      setLoading(false)
      setIsAuthenticated(true)
    }
  }, [auth0Client])

  if (!auth0Client) {
    return <Splash />
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        loading,
        popupOpen,
        token,
        permissions,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}