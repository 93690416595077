import { createMuiTheme,useMediaQuery } from "@material-ui/core"
import { useMemo } from "react"

export const useTheme = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
  return useMemo(() => {
    return createMuiTheme({
      palette: {
        type: prefersDarkMode ? "dark" : "light"
      }
    })
  }, [prefersDarkMode])
}
