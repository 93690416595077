import { Button, createStyles,Grid,makeStyles,TextField, Theme, Typography } from "@material-ui/core"
import React, { useCallback } from "react"
import {useForm} from "react-hook-form"
import { useHistory,useParams } from "react-router-dom"

import { PaperGrid } from "../../components/basic/PaperGrid"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { parseDynamicPath,RouteDefinitions } from "../../components/structure/Routes"
import { useCreateLicenseMutation, useProductQuery } from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1, 0),
      }
    }
  })
)

type FormData = {
  keyData: string
}

export const AddLicense: React.FC = () => {
  const classes = useStyles()
  const {activeTenant} = useTenant()
  const {id: productId} = useParams<{id: string}>()
  const {data: productData} = useProductQuery({
    variables: {
      id: productId,
      tenantId: activeTenant.id,
      includeLicenses: false
    }
  })
  const [mutate, {data, loading}] = useCreateLicenseMutation()
  const {register, handleSubmit, errors} = useForm<FormData>()
  const history = useHistory()

  React.useEffect(() => {console.log("ERRORS: ", errors)}, [errors])

  const onSubmit = useCallback(
    async ({keyData}: FormData) => {
      if (!loading) {
        await mutate({
          variables: {
            keyData,
            productId: productId,
            tenantId: activeTenant.id
          },
          refetchQueries: ["product", "products"]
        })
        history.goBack()
      }
    }, [activeTenant.id, history, loading, mutate, productId])

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Add License"
        breadcrumbs={[
          { href: RouteDefinitions.products.path, content: "Products"},
          { href: parseDynamicPath(RouteDefinitions.viewProduct.path, {id: productId}), content: productData?.product.name}
        ]}
      />
      <PaperGrid item xs={12}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={classes.root}>
          <TextField
            name="keyData"
            label="License Key"
            inputRef={register({
              required: "License key is required.",
              minLength: {
                value: 10,
                message: "License key needs to be a minimum of 10 characters long."
              }
            })}
            error={!!errors.keyData}
            helperText={errors.keyData?.message}
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary" disabled={loading}>Create</Button>
        </form>
        {data && <Typography>{data.createLicense.id}</Typography>}
      </PaperGrid>
    </Grid>
  )
}