import { Button,CircularProgress, createStyles,Grid, makeStyles, Modal,Paper,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from "@material-ui/core"
import React, { Dispatch,useCallback, useState } from "react"
import {useDropzone} from "react-dropzone"
import { useHistory,useParams } from "react-router-dom"
import { ApiScopes } from "shared"

import { PaperGrid } from "../../components/basic/PaperGrid"
import { PermissionGate } from "../../components/basic/PermissionGate"
import { RouteButton } from "../../components/basic/RouteButton"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { DefaultPerPage,Pager } from "../../components/pager/Pager"
import { ProductForm } from "../../components/product-form/ProductForm"
import { SplitButton } from "../../components/split-button/SplitButton"
import { RouteDefinitions } from "../../components/structure/Routes"
import {ProductQuery, useProductQuery,useUpdateProductMutation,useUploadLicensesMutation} from "../../graphql"
import { usePermissions } from "../../hooks/use-permissions"
import { useTenant } from "../../providers/TenantProvider"
import { PagingProps } from "../../utilities/PagingProps"

type Variables = {
  id: string
  tenantId: string
  includeLicenses: boolean
}
type QueryProps = Variables & PagingProps

export const Product: React.FC = () => {
  const history = useHistory()
  const canUpdate = usePermissions([ApiScopes.AdminProducts])
  const {activeTenant} = useTenant()
  const [showUpload, setShowUpload] = useState(false)
  const {id} = useParams<{id: string}>()
  const [variables, setVariables] = useState<QueryProps>({
    id,
    tenantId: activeTenant.id,
    includeLicenses: true,
    first: DefaultPerPage[0]
})
  const {data, loading} = useProductQuery({variables})
  const [updateProduct,{loading: updateLoading}] = useUpdateProductMutation()
  const onSubmit = useCallback(
    async (input) => {
      if (!loading) {
        await updateProduct({
          variables: {
            id,
            input,
            tenantId: activeTenant.id
          },
          refetchQueries: ["products"]
        })
        history.goBack()
      }
    }, 
    [loading, updateProduct, id, activeTenant.id, history])

  const getPage = useCallback(
    (pageProps: PagingProps) => {
      setVariables({
        id: id,
        tenantId: activeTenant.id,
        includeLicenses: true,
        ...pageProps
      })
    }, [activeTenant.id, id])

  // TODO: Actual loading screen
  if (!data) {
    return <div>...</div>
  }

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title={data?.product.name || "..."}
        breadcrumbs={[
          { href: RouteDefinitions.products.path, content: "Products"}
        ]}
        primaryAction={
          <>
            <PermissionGate permissions={RouteDefinitions.createLicense.permissions}>
              <SplitButton
                variant="contained"
                color="primary"
                options={[
                  {
                    title: "Upload Licenses via CSV",
                    onClick: () => setShowUpload(true)
                  }
                ]}
              >
                <RouteButton
                  route={RouteDefinitions.createLicense}
                  parameters={{id}}
                  variant="contained" 
                  color="primary"
                >
                  <Typography>Add License</Typography>
                </RouteButton>
              </SplitButton>
            </PermissionGate>
            <LicenseUpload productId={id} tenantId={activeTenant.id} show={showUpload} dismiss={() => setShowUpload(false)}/>
          </>
        }
        secondaryActions={[
          {
            text: "View Activity",
            route: RouteDefinitions.viewProductActivity,
            parameters: { id }
          }
        ]}
      />
      <PaperGrid item xs={12}>
        <ProductForm
          initialValues={{name: data?.product.name, emailTemplateId: data?.product.emailTemplate?.id}}
          submitText="Save"
          disabled={!canUpdate || updateLoading}
          onSubmit={onSubmit}
        />
      </PaperGrid>
      {loading && <Typography>Loading...</Typography>}
      {!loading &&
        <LicenseGrid query={data} getPage={getPage}/>
      }
    </Grid>
  )
}

type LicenseGridProps = {
  query: ProductQuery
  getPage: Dispatch<PagingProps>
}

const LicenseGrid: React.FC<LicenseGridProps> = ({query: {product}, getPage}) => {
  const {totalCount, edges, pageInfo} = product.licenses
  
  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.map(({node: {id, status, createdAt}}) => 
              <TableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell>{status}</TableCell>
                <TableCell>{createdAt}</TableCell>
              </TableRow>
            )}
            {!edges.length && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography>No licenses found.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pager 
        totalCount={totalCount}
        pageInfo={pageInfo}
        setPage={getPage}
        />
    </Grid>
  )
}

type LicenseUploadProps = {
  productId: string
  tenantId: string
  show: boolean
  dismiss: Dispatch<void>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperModal: {
      position: "absolute",
      width: 400,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }
  }),
)

const LicenseUpload: React.FC<LicenseUploadProps> = ({productId, tenantId, show, dismiss}) => {
  const classes = useStyles()
  const [mutate, {loading, error}] = useUploadLicensesMutation()
  const [file, setFile] = useState<File>()

  const onDrop = ([x]: File[]) => setFile(x)

  const upload = useCallback(
    async () => {
      try {
        await mutate({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          variables: {productId, tenantId, csv: file as any},
          refetchQueries: ["product"]
        })
        setFile(undefined)
        dismiss()
      } catch (e) {
        console.error(e)
      }
    },
    [file, mutate, productId, tenantId, dismiss]
  )

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Modal open={show} onClose={() => dismiss()}>
      <div className={classes.paperModal}>
        <div {...getRootProps()}>
          <input {...getInputProps()}/>
          { isDragActive
              ? <Typography>drop it like it&apos;s hot</Typography>
              : <Typography>drag and drop or click here to select</Typography>
          }
        </div>
        {error && <Typography>{error.message}</Typography>}
        <Button disabled={!file || loading} onClick={upload}>
          <Typography>
            Upload
            {loading && <CircularProgress size={24}/>}
          </Typography>
        </Button>
      </div>
    </Modal>
  )
}