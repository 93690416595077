import { Button, createStyles,FormControlLabel, Grid, makeStyles, Switch, TextField, Theme, Typography } from "@material-ui/core"
import React, { useCallback,useEffect,useState } from "react"
import { useForm } from "react-hook-form"

import { TenantQuery,useRemoveTenantSendGridConfigMutation, useUpdateTenantSendGridConfigMutation } from "../../graphql"

type SendGridConfigView = TenantQuery["tenant"]["sendGridConfig"]

export type SendGridSettingsProps = {
  tenantId: string
  sendGridConfig: SendGridConfigView
}

export const SendGridSettings: React.FC<SendGridSettingsProps> = ({tenantId, sendGridConfig}) => {
  const [usingDefault, setUsingDefault] = useState(!sendGridConfig)
  const [removeSettings, {loading: removingSettings}] = useRemoveTenantSendGridConfigMutation()

  const reset = useCallback(
    async (useDefault) => {
      setUsingDefault(useDefault)
      if (useDefault) {
        await removeSettings({variables: {tenantId}})
      }
    },
    [removeSettings, tenantId]
  )

  return (
    <Grid container item xs={12}>
      <Grid item xs={4}>
        <FormControlLabel
          value="top"
          control={<Switch checked={usingDefault} color="primary" onChange={(x) => reset(x.target.checked)}/>}
          label={<Typography color="textSecondary" variant="caption">Use Default</Typography>}
          labelPlacement="top"
        />
      </Grid>
      <SendGridSettingsEditor disabled={usingDefault || removingSettings} tenantId={tenantId} sendGridConfig={sendGridConfig}/>
    </Grid>
  )
}

type SendGridSettingsEditorProps = {
  tenantId: string
  sendGridConfig: SendGridConfigView
  disabled?: boolean
}

const SendGridSettingsEditor: React.FC<SendGridSettingsEditorProps> = ({tenantId, sendGridConfig, disabled=false}) => {
  type FormData = {
    from: string
    apiKey: string
  }
  const { register, handleSubmit, errors, formState: {dirty}, reset } = useForm<FormData>()
  const [mutate, {loading: updating}] = useUpdateTenantSendGridConfigMutation()

  useEffect(
    () => {
      reset({
        from: sendGridConfig?.from
      })
    }, 
    [reset, sendGridConfig])

  const submit = useCallback(async ({from, apiKey}: FormData) => {
      await mutate({
        variables: {
          tenantId,
          from,
          apiKey
        },
        refetchQueries: ["getTenant"]
      })
    }, 
    [mutate, tenantId])

  const classes = useStyles()

  return (
      <form className={classes.editor} noValidate autoComplete="off" onSubmit={handleSubmit(submit)}>
        <Grid container item spacing={3} xs={12}>
          <Grid item xs={5}>
            <TextField
                name="from"
                placeholder="EMail"
                label="From"
                inputRef={register({
                  required: "From is required.",
                  pattern: {
                    value: /^[A-Z!._%+-]+@(?:[A-Z0-9]+(?:-[A-Z0-9]+)*\.)+[A-Z]{2,}$/i,
                    message: "Please enter a valid email address"
                  }
                })}
                InputLabelProps={{shrink: true}}
                error={!!errors.from}
                helperText={errors.from?.message}
                fullWidth
                disabled={disabled}
              />
          </Grid>
          <Grid item xs={5}>
            <TextField
                name="apiKey"
                placeholder="hidden"
                label="Api Key"
                inputRef={register()}
                InputLabelProps={{shrink: true}}
                error={!!errors.apiKey}
                helperText={errors.apiKey?.message}
                fullWidth
                disabled={disabled}
              />
          </Grid>
          <Grid item xs={1}>
            <Button type="submit" disabled={disabled || !dirty || updating} variant="contained" color="primary">Save</Button>
          </Grid>
        </Grid>
      </form>
   )
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    editor: {
      padding: theme.spacing(2)
    }
  }))
