import React, { Dispatch,useContext, useEffect,useState } from "react"

import { useMyTenantsQuery } from "../graphql"
import { Splash } from "../screens/splash/Splash"

export type Tenant = {
  id: string
  name: string
  status: string
}

type Context = {
  tenants: Tenant[]
  activeTenant: Tenant
  setActiveTenant: Dispatch<Tenant>
}

export const TenantContext = React.createContext<Context | null>(null)
export const useTenant = () => useContext(TenantContext)!

type TenantProviderProps = {}

export const TenantProvider: React.FC<TenantProviderProps> = ({ children }) => {
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [activeTenant, setActiveTenant] = useState<Tenant>()
  const {data, loading} = useMyTenantsQuery()

  useEffect(() => {
      setTenants(data?.myTenants || [])
      if (!data?.myTenants.length) {
        setActiveTenant(undefined)
      } else {
        setActiveTenant(data.myTenants[0])
      }
  }, [data])

  if (loading) {
    return <Splash />
  }

  if (!activeTenant) {
    return <div>No active tenants :(</div>
  }

  return (
    <TenantContext.Provider value={{
      tenants,
      activeTenant,
      setActiveTenant
    }}>
      {children}
    </TenantContext.Provider>
  )
}