import { Button,createStyles,Grid,makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js"
import {stateToHTML} from "draft-js-export-html"
import RichTextEditor from "mui-rte"
import { TCustomControl, TToolbarButtonSize,TToolbarControl } from "mui-rte/src/components/Toolbar"
import { TAutocomplete, TDecorator } from "mui-rte/src/MUIRichTextEditor"
import React, { useCallback, useEffect, useState } from "react"
import { useDebounce, useDebouncedCallback } from "use-debounce/lib"

import { usePrevious } from "../../hooks/use-previous"

const stringToRawContentStateJson = (value: string) => {
  const {contentBlocks, entityMap} = convertFromHTML(value)
  const state = ContentState.createFromBlockArray(contentBlocks, entityMap)
  return JSON.stringify(convertToRaw(state))
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helperText: {
      marginTop: theme.spacing(2.5)
    }
  })
)

//#region mui-rte props redefined because they didn't export it :)
type TKeyCommand = {
  key: number
  name: string
  callback: (state: EditorState) => EditorState
}

type TDraftEditorProps = {
    spellCheck?: boolean
    stripPastedStyles?: boolean
}

type RichTextEditorProps = {
  id?: string
  /**
   * @deprecated Use `defaultValue` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any
  label?: string
  readOnly?: boolean
  inheritFontSize?: boolean
  error?: boolean
  controls?: Array<TToolbarControl>
  customControls?: TCustomControl[]
  decorators?: TDecorator[]
  toolbar?: boolean
  toolbarButtonSize?: TToolbarButtonSize
  inlineToolbar?: boolean
  inlineToolbarControls?: Array<TToolbarControl>
  draftEditorProps?: TDraftEditorProps
  keyCommands?: TKeyCommand[]
  maxLength?: number
  onSave?: (data: string) => void
  onChange?: (state: EditorState) => void
  autocomplete?: TAutocomplete
}
//#endregion

export type HtmlEditorProps = {
  defaultValue?: string
  onChange?: (value: string) => void
  onChangeDebounceDelay?: number
  contentStateDebounceDelay?: number
  disabled?: boolean
  helperText?: React.ReactNode
} & Omit<RichTextEditorProps, "defaultValue" | "onChange" | "readOnly">

export const HtmlEditor: React.FC<HtmlEditorProps> = ({defaultValue, onChange, onChangeDebounceDelay, contentStateDebounceDelay, label, disabled, error, helperText, ...rteProps}) => {
  const classes = useStyles()
  const [contentState, setContentState] = useState<ContentState>()
  const [debouncedContentState] = useDebounce(contentState, contentStateDebounceDelay || 500)

  const [debouncedOnChange] = useDebouncedCallback((value: string) => {
    if (onChange) {
      onChange(value)
    }
  }, onChangeDebounceDelay || 0)

  const [raw, setRaw] = useState<string>()
  const previousRaw = usePrevious(raw)
  const [viewRaw, setViewRaw] = useState(false)

  const [rteDefaultValue, setRteDefaultValue] = useState<string>()

  useEffect(() => {
    if (defaultValue) {
      setRteDefaultValue(stringToRawContentStateJson(defaultValue))
    }
  }, [defaultValue])

  useEffect(() => {
    if (debouncedContentState) {
      setRaw(stateToHTML(debouncedContentState))
    }
  }, [debouncedContentState])

  useEffect(() => {
    if (raw && raw !== previousRaw) {
      debouncedOnChange(raw)
    }
  }, [raw, previousRaw, debouncedOnChange])

  const toggleRaw = useCallback(() => {
    console.log("toggle")
    setViewRaw(isCurrentlyRaw => {
      if (isCurrentlyRaw && raw && rteDefaultValue !== raw) {
        setRteDefaultValue(stringToRawContentStateJson(raw))
      }
      return !isCurrentlyRaw
    })
  }, [raw, rteDefaultValue])

  return (
    <>
      {!viewRaw
        ? (
          <>
            <Grid item xs={12}>
              <Typography variant="caption" color={!error ? "textSecondary" : "error"}>{label}</Typography>
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                defaultValue={rteDefaultValue}
                onChange={s => setContentState(s.getCurrentContent())}
                readOnly={disabled}
                error={error}
                {...rteProps}
              />
            </Grid>
            {helperText &&
              <Grid item xs={12} className={classes.helperText}>
                <Typography variant="caption" color={!error ? "textSecondary" : "error"}>{helperText}</Typography>
              </Grid>
            }
          </>
        )
        : (
          <Grid item xs={12}>
            <TextField
              label={label}
              InputLabelProps={{shrink: true}}
              value={raw || defaultValue}
              onChange={e => {
                const value = e.target.value
                setRaw(value)
              }}
              rows={10}
              disabled={disabled}
              error={error}
              helperText={helperText}
              multiline
              fullWidth
            />
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Button onClick={() => toggleRaw()}>View {!viewRaw ? "Source" : "Editor"}</Button>
      </Grid>
    </>
  )
}