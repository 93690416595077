import { Button,FormControl, Grid, InputLabel,MenuItem,Select,TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { OnSubmit, useForm } from "react-hook-form"

import { useEmailTemplatesQuery } from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"

export type ProductFormData = {
  name: string
  emailTemplateId?: string
}

export type ProductFormProps = {
  submitText: string
  disabled: boolean
  submitDisabled?: boolean
  onSubmit: OnSubmit<ProductFormData>
  initialValues?: ProductFormData
}

export const ProductForm: React.FC<ProductFormProps> = ({ submitText, disabled, submitDisabled, onSubmit, initialValues }) => {
  const {activeTenant} = useTenant()
  const { register, handleSubmit, reset, errors, setValue, formState: {dirty} } = useForm<ProductFormData>()
  const [ emailTemplateId, setEmailTemplateId ] = useState(initialValues?.emailTemplateId)
  const {data} = useEmailTemplatesQuery({
    variables: {
      tenantId: activeTenant.id
    }
  })

  useEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  useEffect(() => {
    register({name: "emailTemplateId"})
  }, [register])

  const handleEmailTemplateIdChange = (value?: string) => {
    setValue("emailTemplateId", value)
    setEmailTemplateId(value)
  }

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Name"
            inputRef={register({ required: "Name is required." })}
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            error={!!errors.name}
            helperText={errors.name?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="product-email-template" shrink>Email Template</InputLabel>
            <Select
              id="emailTemplateId"
              name="emailTemplateId"
              labelId="product-email-template"
              value={emailTemplateId}
              onChange={e => handleEmailTemplateIdChange(e.target.value as string)}
              displayEmpty
            >
              <MenuItem value={undefined}><em>Default</em></MenuItem>
              {
                data?.emailTemplates.edges.map(({node}) => <MenuItem key={`email-template-${node.id}`} value={node.id}>{node.name}</MenuItem>)
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" disabled={!dirty || submitDisabled || disabled} variant="contained" color="primary">{submitText}</Button>
        </Grid>
      </Grid>
    </form>
  )
}