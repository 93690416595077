import { Grid, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { Route, RouteProps } from "react-router-dom"

import { usePermissions } from "../../hooks/use-permissions"
import { useAuth0 } from "../../providers/Auth0Provider"
import {ScreenHeader} from "./ScreenHeader"

const NotAuthorized: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <ScreenHeader title="Not Authorized" />
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">You are not authorized to view this page.</Typography>
      </Grid>
    </Grid>
  )
}

export type PrivateRouteProps = RouteProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
  permissions?: string[]
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, permissions, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const hasPermission = usePermissions(permissions)

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    (async () => {
      await loginWithRedirect({
        appState: {targetUrl: window.location.pathname}
      })
    })()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  return (
    <Route
      path={path}
      render={(props) => {
        if (!isAuthenticated) return null
        if (!hasPermission) return <NotAuthorized />
        return <Component {...props} />
      }
      }
      {...rest}
    />
  )
}