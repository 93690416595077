import { createStyles, FormControlLabel, Grid, GridProps,IconButton,makeStyles,MenuItem, Select, Theme, Typography } from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import React, { Dispatch, useCallback,useState } from "react"

import { PageInfo } from "../../graphql"
import { PagingProps } from "../../utilities/PagingProps"

type PagerProps = {
  totalCount: number
  pageInfo: PageInfo
  disabled?: boolean
  setPage: Dispatch<PagingProps>
  perPage?: number[]
} & GridProps

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    formLabel: {
      marginRight: theme.spacing(1)
    },
    fullWidthCell: {
      width: "99%",
      alignContent: "right"
    }
  }),
)

export const DefaultPerPage = [5, 10, 20]
export const Pager: React.FC<PagerProps> = ({totalCount, pageInfo, setPage, disabled, perPage = DefaultPerPage, ...gridProps}) => {
  const {hasNextPage, hasPreviousPage, endCursor, startCursor} = pageInfo
  const [pageSize, setPageSize] = useState(perPage[0])
  const [current, setCurrent] = useState(0)

  const firstPage = useCallback(
    () => {
      setPage({first: pageSize})
      setCurrent(0)
    },
    [pageSize, setPage]
  )
  
  const nextPage = useCallback(
    () => {
      setPage({after: endCursor, first: pageSize})
      setCurrent(current => current + pageSize)
    },
    [endCursor, pageSize, setPage]
  )

  const prevPage = useCallback(
    () => {
      setPage({before: startCursor, last: pageSize})
      setCurrent(current => current - pageSize)
    },
    [pageSize, setPage, startCursor]
  )

  const changePageSize = useCallback(
    (n: number) => {
      setPageSize(n)
      setCurrent(0)
      setPage({first: n})
    },
    [setPage]
  )
  const classes = useStyles()

  return (
    <Grid {...gridProps} container item xs={12} alignItems="center" justify="flex-end">
      <FormControlLabel  
        className={classes.formControl} 
        label={<Typography className={classes.formLabel}>Rows per page</Typography>}
        labelPlacement="start"
        control={
          <Select
            disabled={disabled}
            labelId="per-page-label"
            name="Per Page"
            value={pageSize}
            onChange={(e) => changePageSize(e.target.value as number)}
            >
            {perPage.map(pp => <MenuItem key={pp} value={pp}>{pp}</MenuItem>)}
          </Select>
        } 
      />
      {current+1}-{Math.min(current + pageSize, totalCount)} of {totalCount}
      <IconButton disabled={disabled || !hasPreviousPage} onClick={firstPage}><FirstPageIcon/></IconButton>
      <IconButton disabled={disabled || !hasPreviousPage} onClick={prevPage}><ChevronLeftIcon/></IconButton>
      <IconButton disabled={disabled || !hasNextPage} onClick={nextPage}><ChevronRightIcon/></IconButton>
    </Grid>
   )
}