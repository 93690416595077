import {Grid} from "@material-ui/core"
import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"

import { PaperGrid } from "../../components/basic/PaperGrid"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { ProductForm } from "../../components/product-form/ProductForm"
import { RouteDefinitions } from "../../components/structure/Routes"
import {useCreateProductMutation} from "../../graphql"
import { usePermissions } from "../../hooks/use-permissions"
import { useTenant } from "../../providers/TenantProvider"

export const CreateProduct: React.FC = () => {
  const history = useHistory()
  const canCreate = usePermissions(RouteDefinitions.createProduct.permissions)
  const {activeTenant} = useTenant()
  const [createProduct,{loading}] = useCreateProductMutation()
  const onSubmit = useCallback(
    async (input) => {
      if (!loading) {
        await createProduct({
          variables: {
            input,
            tenantId: activeTenant.id
          },
          refetchQueries: ["products"]
        })
        history.goBack()
      }
    }, 
    [activeTenant, createProduct, loading, history])

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Create"
        breadcrumbs={[
          { href: RouteDefinitions.products.path, content: "Products"}
        ]}
      />
      <PaperGrid item xs={12}>
        <ProductForm
          submitText="Create"
          disabled={!canCreate || loading}
          onSubmit={onSubmit}
        />
      </PaperGrid>
    </Grid>
  )
}
