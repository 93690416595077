import {Divider, Drawer, IconButton} from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import clsx from "clsx"
import React from "react"

import {NavBar} from "../nav-bar/NavBar"
import { useStyles } from "./styles"

export type SideBarProps = {
  onDrawerClose: () => void
  drawerOpen: boolean
}

export const SideBar: React.FC<SideBarProps>  = ({onDrawerClose, drawerOpen}) => {
  const classes = useStyles()
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
      }}
      open={drawerOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <NavBar/>
    </Drawer>
  )
}