import {Grid} from "@material-ui/core"
import React from "react"

import { ActivityList } from "../../components/activity/ActivityList"
import { ScreenHeader } from "../../components/basic/ScreenHeader"

export const Activity: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <ScreenHeader title="Activity" />
      <ActivityList showLinks />
    </Grid>
  )
}

