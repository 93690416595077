import {Box, Container, CssBaseline, LinearProgress,ThemeProvider} from "@material-ui/core"
import React, { useMemo,useState } from "react"
import {Router} from "react-router-dom"

import { useAuth0 } from "../../providers/Auth0Provider"
import { useApolloStatus } from "../../providers/AuthenticatedApolloProvider"
import { Splash } from "../../screens/splash/Splash"
import { useTheme } from "../../styles/theme"
import {history} from "../../utilities/history"
import {Copyright} from "./Copyright"
import { Header } from "./Header"
import { Routes } from "./Routes"
import { SideBar } from "./SideBar"
import { useStyles } from "./styles"

export const App = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { loading: auth0Loading } = useAuth0()
  const { loading: apolloLoading } = useApolloStatus()
  const [drawerOpen, setDrawerOpen] = useState(true)
  const loaderHeight = useMemo(() => theme.spacing(0.5), [theme])

  if (auth0Loading) {
    return <Splash />
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Router history={history}>
          <Header drawerOpen={drawerOpen} onDrawerOpen={() => setDrawerOpen(true)} />
          <SideBar drawerOpen={drawerOpen} onDrawerClose={() => setDrawerOpen(false)}/>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {apolloLoading && <LinearProgress variant="indeterminate" style={{height: loaderHeight}} />}
            <Container maxWidth="lg" className={classes.container} style={{marginTop: apolloLoading ? (-1 * loaderHeight) : 0}}>
              <Routes />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
        </Router>
      </div>
    </ThemeProvider>
  )
}
