import {Grid, Paper,Table,TableBody,TableCell,TableContainer,TableHead, TableRow, Typography} from "@material-ui/core"
import React, {CSSProperties,useCallback,useState} from "react"

import { RouteButton } from "../../components/basic/RouteButton"
import { RouteLink } from "../../components/basic/RouteLink"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { DefaultPerPage,Pager } from "../../components/pager/Pager"
import { RouteDefinitions } from "../../components/structure/Routes"
import {EmailTemplate, EmailTemplatesQuery,EmailTemplatesQueryVariables,useEmailTemplatesQuery} from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"
import { PagingProps } from "../../utilities/PagingProps"

export const EmailTemplates: React.FC = () => {
  const {activeTenant} = useTenant()
  const [variables, setVariables] = useState<EmailTemplatesQueryVariables>({
    tenantId: activeTenant.id,
    first: DefaultPerPage[0]
  })
  const {data, loading} = useEmailTemplatesQuery({variables})
  const setPage = useCallback(
    (pp: PagingProps) => {
      setVariables({
        tenantId: activeTenant.id,
        ...pp
      })
    },
    [activeTenant.id]
  )

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Email Templates"
        primaryAction={
          <RouteButton route={RouteDefinitions.createEmailTemplate} variant="contained" color="primary">
            Create
          </RouteButton>
        }
      />
      <Grid item xs={12}>
        <EmailTemplatesGrid templates={data} />
        {data && 
          <Pager 
            pageInfo={data.emailTemplates.pageInfo}
            totalCount={data.emailTemplates.totalCount}
            setPage={setPage}
            disabled={loading}
          />
        }
      </Grid>
    </Grid>
  )
}

const styles: {[k: string]: CSSProperties} = {
  cell: {
    whiteSpace: "nowrap"
  },
  fullWidthCell: {
    width: "99%"
  }
}

type EmailTemplatesGridProps = {
  templates?: EmailTemplatesQuery
}

const EmailTemplatesGrid: React.FC<EmailTemplatesGridProps> = ({templates}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={styles.fullWidthCell}>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates?.emailTemplates.edges.map(({node}) => (<EmailTemplateRow key={node.id} template={node} />))}
          {!templates?.emailTemplates.edges.length && (
            <TableRow>
              <TableCell colSpan={1}>
              <Typography>No email templates found.</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type EmailTemplateRowProps = {
  template: Partial<EmailTemplate>
}

const EmailTemplateRow: React.FC<EmailTemplateRowProps> = ({template}) => {
  return (
    <TableRow>
      <TableCell style={styles.fullWidthCell} component="th" scope="row">
        <RouteLink
          route={RouteDefinitions.viewEmailTemplate}
          parameters={{id: template.id!}}
          color="inherit"
        >
          {template.name}
        </RouteLink>
      </TableCell>
    </TableRow>
  )
}