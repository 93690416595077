import { Grid } from "@material-ui/core"
import React from "react"
import { useParams } from "react-router-dom"

import { ActivityList } from "../../components/activity/ActivityList"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { parseDynamicPath,RouteDefinitions } from "../../components/structure/Routes"
import { useProductQuery } from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"

export const ProductActivity: React.FC = () => {
  const {activeTenant} = useTenant()
  const {id} = useParams<{id: string}>()
  const {data: productData} = useProductQuery({
    variables: {
      id,
      tenantId: activeTenant.id,
      includeLicenses: false
    }
  })

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Activity"
        breadcrumbs={[
          { href: RouteDefinitions.products.path, content: "Products"},
          { href: parseDynamicPath(RouteDefinitions.viewProduct.path, {id}), content: productData?.product.name}
        ]}
      />
      <ActivityList kind="Product" linkId={id}/>
    </Grid>
  )
}