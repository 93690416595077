import {Link as MuiLink, LinkProps as MuiLinkProps} from "@material-ui/core"
import React from "react"
import {Link as RouterLink} from "react-router-dom"

export type LinkProps = MuiLinkProps

export const Link: React.FC<LinkProps> = (props) => {
  const {href} = props
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <MuiLink component={RouterLink as any} to={href} {...props} />
  )
}
