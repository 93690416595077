import { Button,Checkbox,createStyles,Grid, List, ListItem,ListItemIcon, ListItemText, makeStyles, Theme } from "@material-ui/core"
import React, { Dispatch, useEffect,useMemo, useState } from "react"

import { ProductsQuery,useProductsQuery } from "../../graphql"
import { usePrevious } from "../../hooks/use-previous"
import { useTenant } from "../../providers/TenantProvider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
)

type ProductView = ProductsQuery["products"]["edges"][0]["node"]

export type ProductSelectorProps = {
  onSelect: Dispatch<ProductView[]>
  excludeIds?: string[]
  allowNoAvailableLicenseSelection?: boolean
  singleSelect?: boolean
  autoSelect?: boolean
}

export const ProductSelector: React.FC<ProductSelectorProps> = ({excludeIds, onSelect, allowNoAvailableLicenseSelection, singleSelect, autoSelect}) => {
  const classes = useStyles()
  const [selectedProducts, setSelectedProducts] = useState<ProductView[]>([])
  const previousSelectedProducts = usePrevious(selectedProducts)
  const {activeTenant} = useTenant()
  const {data} = useProductsQuery({
    variables: {tenantId: activeTenant.id}
  })
  const products = useMemo(() => {
    return data?.products.edges.map(e => e.node).filter( p => !excludeIds || !excludeIds.includes(p.id))
  }, [data, excludeIds])

  useEffect(() => {
    if (autoSelect) {
      const previousIds = (previousSelectedProducts || []).map(p => p.id)
      const currentIds = selectedProducts.map(p => p.id)
      if (currentIds.filter(i => !previousIds.includes(i)).length !== 0) {
        onSelect(selectedProducts)
      }
    }
  }, [autoSelect, previousSelectedProducts, selectedProducts, onSelect])

  if (!products?.length) {
    return null
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List className={classes.root}>
          {products.map(product => {
            const selected = selectedProducts.findIndex(p => p.id! === product.id!) !== -1

            const licenseDisabled = !allowNoAvailableLicenseSelection && !product.availableLicenseCount
            const selectionDisabled = !!singleSelect && selectedProducts.length === 1 && !selected

            const disabled = licenseDisabled || selectionDisabled
            return (
              <ProductRow
                key={`product-row-${product.id}`}
                product={product}
                selected={selected}
                disabled={disabled}
                onToggle={() => {
                  if (selected) {
                    setSelectedProducts(selectedProducts.filter(p => p.id !== product.id))
                  } else {
                    setSelectedProducts([...selectedProducts, product])
                  }
                }}
                />
            )
          })}
        </List>
      </Grid>
      {!autoSelect &&
        <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
          <Button variant="contained" color="primary" disabled={!selectedProducts.length} onClick={() => onSelect(selectedProducts)}>Select</Button>
        </Grid>
      }
    </Grid>
  )
}

type ProductRowProps = {
  product: ProductView
  selected: boolean
  onToggle: Dispatch<void>
  disabled: boolean
}

const ProductRow: React.FC<ProductRowProps> = ({product, selected, onToggle, disabled}) => {
  const labelId = useMemo(() => `checkbox-list-label-${product.id}`, [product])
  return (
    <ListItem role={undefined} dense button onClick={() => onToggle()} disabled={disabled}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={selected}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={product.name} secondary={`${product.availableLicenseCount} license${product.availableLicenseCount === 1 ? "" : "s"}`} />
    </ListItem>
  )
}