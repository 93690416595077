import { createStyles,Grid, GridProps, makeStyles, Paper, PaperProps, Theme } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    }
  })
)

export type PaperGridProps = {
  paperProps?: PaperProps
} & GridProps

export const PaperGrid: React.FC<PaperGridProps> = ({paperProps, children, ...gridProps}) => {
  const classes = useStyles()
  return (
    <Grid {...gridProps}>
      <Paper className={classes.paper} {...paperProps}>
        {children}
      </Paper>
    </Grid>
  )
}