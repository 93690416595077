import { Backdrop,CircularProgress } from "@material-ui/core"
import React from "react"

export type SplashProps = {}

export const Splash: React.FC<SplashProps> = () => {
  return (
    <Backdrop open={true}>
      <CircularProgress />
    </Backdrop>
  )
}