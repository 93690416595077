import {Grid, Paper,Table, TableBody,TableCell,TableContainer, TableHead, TableRow, Typography} from "@material-ui/core"
import Moment from "moment"
import React, { useCallback, useState } from "react"

import { AuditLogsQuery,AuditLogsQueryVariables,useAuditLogsQuery } from "../../graphql"
import { useTenant } from "../../providers/TenantProvider"
import {PagingProps} from "../../utilities/PagingProps"
import { RouteButton } from "../basic/RouteButton"
import { DefaultPerPage,Pager } from "../pager/Pager"
import { RouteDefinitions } from "../structure/Routes"

export type ActivityProps = {
  showLinks?: boolean
} & Omit<AuditLogsQueryVariables, keyof PagingProps | "tenantId">

export const ActivityList: React.FC<ActivityProps> = ({kind, linkId, showLinks}) => {
  const {activeTenant} = useTenant()
  const [variables, setVariables] = useState<AuditLogsQueryVariables>({
    tenantId: activeTenant.id,
    kind,
    linkId,
    first: DefaultPerPage[0]
  })
  const {data, loading, error} = useAuditLogsQuery({variables})
  const setPage = useCallback(
    (pageProps: PagingProps) => setVariables({
      tenantId: activeTenant.id,
      kind,
      linkId,
        ...pageProps
    }), [activeTenant.id, kind, linkId]
  )
  
  return (
    <Grid item container spacing={3}>
      {error && <Typography>{error.message}</Typography>}
      {loading && <Typography>loading...</Typography>}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Kind</TableCell>
              <TableCell>User</TableCell>
              {showLinks && <TableCell>Link</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.auditLogs.edges.map(({node}) => 
                <LogRow key={node.id} node={node} showLink={showLinks}/>
              )}
              {!data?.auditLogs.edges.length && (
                <TableRow>
                  <TableCell colSpan={showLinks ? 5 : 4}>
                    <Typography>No activity found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        { data && 
           <Pager 
            totalCount={data.auditLogs.totalCount}
            pageInfo={data.auditLogs.pageInfo}
            setPage={setPage}
            disabled={loading}/>
        }
      </Grid>
    </Grid>
  )
}

type NodeType = AuditLogsQuery["auditLogs"]["edges"][0]["node"]
type LogRowProps = {
  node: NodeType
  showLink?: boolean
}

const LogRow: React.FC<LogRowProps> = ({node: log, showLink}) => {
  return (
    <TableRow key={log.id}>
      <TableCell>{Moment(log.createdAt).calendar()}</TableCell>
      <TableCell>{log.message}</TableCell>
      <TableCell>{log.kind}</TableCell>
      <TableCell>{log.user.name}</TableCell>
      {showLink && 
        <TableCell>{log.linkId && <LogLink kind={log.kind} linkId={log.linkId}/>}</TableCell>
      }
    </TableRow>
  )
}

const LogLink: React.FC<{kind: string, linkId: string}> = ({kind, linkId}) => {
  switch (kind) {
    case "fulfillment": 
      // i'll use that RouteLink thing when available
      return <RouteButton route={RouteDefinitions.viewFulfillment} parameters={{id: linkId}}>Open</RouteButton>
    case "product":
      return <RouteButton route={RouteDefinitions.viewProduct} parameters={{id: linkId}}>Open</RouteButton>
    default:
      return null
  }
}