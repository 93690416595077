import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"
import gql from "graphql-tag"
import { Upload } from "shared"
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: Date
  /** The `Upload` scalar type represents a file upload. */
  Upload: Upload
};

export type AuditLog = {
   __typename?: "AuditLog"
  id: Scalars["ID"]
  tenant: Tenant
  user: User
  message: Scalars["String"]
  createdAt: Scalars["DateTime"]
  kind: Scalars["String"]
  linkId?: Maybe<Scalars["String"]>
  subLinkId?: Maybe<Scalars["String"]>
};

export type AuditLogConnection = {
   __typename?: "AuditLogConnection"
  totalCount: Scalars["Int"]
  pageInfo: PageInfo
  edges: Array<AuditLogEdge>
};

export type AuditLogEdge = {
   __typename?: "AuditLogEdge"
  node: AuditLog
  /** Used in `before` and `after` args */
  cursor: Scalars["String"]
};

export type Auth0UserView = {
   __typename?: "Auth0UserView"
  email?: Maybe<Scalars["String"]>
  emailVerified?: Maybe<Scalars["Boolean"]>
  username?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
  phoneVerified?: Maybe<Scalars["Boolean"]>
  userId?: Maybe<Scalars["String"]>
  createdAt?: Maybe<Scalars["String"]>
  updatedAt?: Maybe<Scalars["String"]>
  picture?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  nickname?: Maybe<Scalars["String"]>
  lastIp?: Maybe<Scalars["String"]>
  lastLogin?: Maybe<Scalars["String"]>
  lastPasswordReset?: Maybe<Scalars["String"]>
  loginsCount?: Maybe<Scalars["Float"]>
  blocked?: Maybe<Scalars["Boolean"]>
  givenName?: Maybe<Scalars["String"]>
  familyName?: Maybe<Scalars["String"]>
};

export type ConnectionArgs = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars["String"]>
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars["String"]>
  /** Paginate first */
  first?: Maybe<Scalars["Int"]>
  /** Paginate last */
  last?: Maybe<Scalars["Int"]>
};


export type EmailTemplate = {
   __typename?: "EmailTemplate"
  id: Scalars["ID"]
  name: Scalars["String"]
  subject: Scalars["String"]
  template: Scalars["String"]
  status: Scalars["String"]
  tenant: Tenant
  createdAt: Scalars["DateTime"]
  updatedAt: Scalars["DateTime"]
};

export type EmailTemplateConnection = {
   __typename?: "EmailTemplateConnection"
  totalCount: Scalars["Int"]
  pageInfo: PageInfo
  edges: Array<EmailTemplateEdge>
};

export type EmailTemplateDeletionResponse = {
   __typename?: "EmailTemplateDeletionResponse"
  deletedId: Scalars["ID"]
  deletedType: Scalars["String"]
};

export type EmailTemplateEdge = {
   __typename?: "EmailTemplateEdge"
  node: EmailTemplate
  /** Used in `before` and `after` args */
  cursor: Scalars["String"]
};

export type EmailTemplateInput = {
  name: Scalars["String"]
  subject: Scalars["String"]
  template: Scalars["String"]
};

export type Fulfillment = {
   __typename?: "Fulfillment"
  id: Scalars["ID"]
  status: Scalars["String"]
  email: Scalars["String"]
  tenant: Tenant
  owner: User
  lineItems: Array<FulfillmentLineItem>
  createdAt: Scalars["DateTime"]
  updatedAt: Scalars["DateTime"]
};

export type FulfillmentConnection = {
   __typename?: "FulfillmentConnection"
  totalCount: Scalars["Int"]
  pageInfo: PageInfo
  edges: Array<FulfillmentEdge>
};

export type FulfillmentEdge = {
   __typename?: "FulfillmentEdge"
  node: Fulfillment
  /** Used in `before` and `after` args */
  cursor: Scalars["String"]
};

export type FulfillmentInput = {
  products: Array<ProductAndCount>
  email: Scalars["String"]
};

export type FulfillmentLineItem = {
   __typename?: "FulfillmentLineItem"
  id: Scalars["ID"]
  status: Scalars["String"]
  fulfillment: Fulfillment
  licenseKey: LicenseKey
  replacedById?: Maybe<Scalars["String"]>
  replacementReason?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  updatedAt: Scalars["DateTime"]
};

export type LicenseKey = {
   __typename?: "LicenseKey"
  id: Scalars["ID"]
  status: Scalars["String"]
  owner: User
  tenant: Tenant
  product: Product
  createdAt: Scalars["DateTime"]
  updatedAt: Scalars["DateTime"]
};

export type LicenseKeyConnection = {
   __typename?: "LicenseKeyConnection"
  totalCount: Scalars["Int"]
  pageInfo: PageInfo
  edges: Array<LicenseKeyEdge>
};

export type LicenseKeyEdge = {
   __typename?: "LicenseKeyEdge"
  node: LicenseKey
  /** Used in `before` and `after` args */
  cursor: Scalars["String"]
};

export type LicenseKeyInput = {
  productId: Scalars["String"]
  keyData: Scalars["String"]
};

export type Mutation = {
   __typename?: "Mutation"
  createEmailTemplate: EmailTemplate
  updateEmailTemplate: EmailTemplate
  deleteEmailTemplate: EmailTemplateDeletionResponse
  createFulfillment: Fulfillment
  replaceLicenseKey: Fulfillment
  fulfillProduct: Fulfillment
  createLicense: LicenseKey
  updateStatus: LicenseKey
  uploadLicenses: Scalars["Boolean"]
  createProduct: Product
  updateProduct: Product
  deleteProduct: ProductDeletionResponse
  createTenant: Tenant
  removeTenantSendGridConfig: Tenant
  updateTenantSendGridConfig: Tenant
};


export type MutationCreateEmailTemplateArgs = {
  tenantId: Scalars["String"]
  input: EmailTemplateInput
};


export type MutationUpdateEmailTemplateArgs = {
  tenantId: Scalars["String"]
  input: EmailTemplateInput
  id: Scalars["String"]
};


export type MutationDeleteEmailTemplateArgs = {
  tenantId: Scalars["String"]
  id: Scalars["String"]
};


export type MutationCreateFulfillmentArgs = {
  tenantId: Scalars["String"]
  input: FulfillmentInput
};


export type MutationReplaceLicenseKeyArgs = {
  reason: Scalars["String"]
  tenantId: Scalars["String"]
  lineItemId: Scalars["String"]
  fulfillmentId: Scalars["String"]
};


export type MutationFulfillProductArgs = {
  tenantId: Scalars["String"]
  productId: Scalars["String"]
  fulfillmentId: Scalars["String"]
};


export type MutationCreateLicenseArgs = {
  tenantId: Scalars["String"]
  input: LicenseKeyInput
};


export type MutationUpdateStatusArgs = {
  tenantId: Scalars["String"]
  status: Scalars["String"]
  id: Scalars["String"]
};


export type MutationUploadLicensesArgs = {
  tenantId: Scalars["String"]
  productId: Scalars["String"]
  csv: Scalars["Upload"]
};


export type MutationCreateProductArgs = {
  tenantId: Scalars["String"]
  input: ProductInput
};


export type MutationUpdateProductArgs = {
  tenantId: Scalars["String"]
  input: ProductInput
  id: Scalars["String"]
};


export type MutationDeleteProductArgs = {
  tenantId: Scalars["String"]
  id: Scalars["String"]
};


export type MutationCreateTenantArgs = {
  tenant: TenantInput
};


export type MutationRemoveTenantSendGridConfigArgs = {
  tenantId: Scalars["String"]
};


export type MutationUpdateTenantSendGridConfigArgs = {
  tenantId: Scalars["String"]
  from: Scalars["String"]
  apiKey?: Maybe<Scalars["String"]>
};

export type PageInfo = {
   __typename?: "PageInfo"
  hasNextPage: Scalars["Boolean"]
  hasPreviousPage: Scalars["Boolean"]
  startCursor?: Maybe<Scalars["String"]>
  endCursor?: Maybe<Scalars["String"]>
};

export type Product = {
   __typename?: "Product"
  id: Scalars["ID"]
  name: Scalars["String"]
  status: Scalars["String"]
  availableLicenseCount: Scalars["Float"]
  tenant: Tenant
  emailTemplate?: Maybe<EmailTemplate>
  createdAt: Scalars["DateTime"]
  updatedAt: Scalars["DateTime"]
  licenses: LicenseKeyConnection
};


export type ProductLicensesArgs = {
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};

export type ProductAndCount = {
  productId: Scalars["String"]
  count: Scalars["Int"]
};

export type ProductConnection = {
   __typename?: "ProductConnection"
  totalCount: Scalars["Int"]
  pageInfo: PageInfo
  edges: Array<ProductEdge>
};

export type ProductDeletionResponse = {
   __typename?: "ProductDeletionResponse"
  deletedId: Scalars["ID"]
  deletedType: Scalars["String"]
};

export type ProductEdge = {
   __typename?: "ProductEdge"
  node: Product
  /** Used in `before` and `after` args */
  cursor: Scalars["String"]
};

export type ProductInput = {
  name: Scalars["String"]
  emailTemplateId?: Maybe<Scalars["String"]>
};

export type Query = {
   __typename?: "Query"
  emailTemplate: EmailTemplate
  sendPreviewEmail: Scalars["Boolean"]
  fulfillment: Fulfillment
  revealKey: Scalars["String"]
  license: LicenseKey
  product: Product
  tenant: Tenant
  me: Auth0UserView
  myTenants: Array<Tenant>
  auditLogs: AuditLogConnection
  emailTemplates: EmailTemplateConnection
  fulfillments: FulfillmentConnection
  licenses: LicenseKeyConnection
  products: ProductConnection
  tenants: TenantConnection
};


export type QueryEmailTemplateArgs = {
  tenantId: Scalars["String"]
  id: Scalars["String"]
};


export type QuerySendPreviewEmailArgs = {
  tenantId: Scalars["String"]
  to: Scalars["String"]
  productId: Scalars["String"]
  id: Scalars["String"]
};


export type QueryFulfillmentArgs = {
  tenantId: Scalars["String"]
  id: Scalars["String"]
};


export type QueryRevealKeyArgs = {
  tenantId: Scalars["String"]
  lineItemId: Scalars["String"]
  fulfillmentId: Scalars["String"]
};


export type QueryLicenseArgs = {
  tenantId: Scalars["String"]
  id: Scalars["String"]
};


export type QueryProductArgs = {
  tenantId: Scalars["String"]
  id: Scalars["String"]
};


export type QueryTenantArgs = {
  tenantId: Scalars["String"]
};


export type QueryAuditLogsArgs = {
  linkId?: Maybe<Scalars["String"]>
  kind?: Maybe<Scalars["String"]>
  tenantId: Scalars["String"]
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type QueryEmailTemplatesArgs = {
  tenantId: Scalars["String"]
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type QueryFulfillmentsArgs = {
  tenantId: Scalars["String"]
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type QueryLicensesArgs = {
  tenantId: Scalars["String"]
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type QueryProductsArgs = {
  tenantId: Scalars["String"]
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type QueryTenantsArgs = {
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};

export type SendGridConfig = {
   __typename?: "SendGridConfig"
  from: Scalars["String"]
};

export type SendGridConfigInput = {
  apiKey: Scalars["String"]
  from: Scalars["String"]
};

export type Tenant = {
   __typename?: "Tenant"
  id: Scalars["ID"]
  name: Scalars["String"]
  status: Scalars["String"]
  keyId?: Maybe<Scalars["String"]>
  sendGridConfig?: Maybe<SendGridConfig>
  createdAt: Scalars["DateTime"]
};

export type TenantConnection = {
   __typename?: "TenantConnection"
  totalCount: Scalars["Int"]
  pageInfo: PageInfo
  edges: Array<TenantEdge>
};

export type TenantEdge = {
   __typename?: "TenantEdge"
  node: Tenant
  /** Used in `before` and `after` args */
  cursor: Scalars["String"]
};

export type TenantInput = {
  name: Scalars["String"]
  sendGridConfig?: Maybe<SendGridConfigInput>
  sendGridApiKey?: Maybe<Scalars["String"]>
  sendGridFrom?: Maybe<Scalars["String"]>
  createEncryptionKey?: Maybe<Scalars["Boolean"]>
};


export type User = {
   __typename?: "User"
  auth0Id: Scalars["String"]
  name: Scalars["String"]
};

export type CreateEmailTemplateMutationVariables = {
  tenantId: Scalars["String"]
  input: EmailTemplateInput
};


export type CreateEmailTemplateMutation = { __typename?: "Mutation", createEmailTemplate: { __typename?: "EmailTemplate", id: string, name: string, subject: string, template: string } };

export type CreateFulfillmentMutationVariables = {
  input: FulfillmentInput
  tenantId: Scalars["String"]
};


export type CreateFulfillmentMutation = { __typename?: "Mutation", createFulfillment: { __typename?: "Fulfillment", id: string, status: string, email: string, lineItems: Array<{ __typename?: "FulfillmentLineItem", id: string, licenseKey: { __typename?: "LicenseKey", id: string, status: string, product: { __typename?: "Product", id: string, name: string, status: string } } }> } };

export type CreateLicenseMutationVariables = {
  keyData: Scalars["String"]
  productId: Scalars["String"]
  tenantId: Scalars["String"]
};


export type CreateLicenseMutation = { __typename?: "Mutation", createLicense: { __typename?: "LicenseKey", id: string, status: string, createdAt: Date, owner: { __typename?: "User", auth0Id: string, name: string }, product: { __typename?: "Product", id: string, name: string, status: string, createdAt: Date } } };

export type CreateProductMutationVariables = {
  input: ProductInput
  tenantId: Scalars["String"]
};


export type CreateProductMutation = { __typename?: "Mutation", createProduct: { __typename?: "Product", id: string, name: string, createdAt: Date } };

export type DeleteEmailTemplateMutationVariables = {
  id: Scalars["String"]
  tenantId: Scalars["String"]
};


export type DeleteEmailTemplateMutation = { __typename?: "Mutation", deleteEmailTemplate: { __typename?: "EmailTemplateDeletionResponse", deletedId: string, deletedType: string } };

export type AuditLogsQueryVariables = {
  tenantId: Scalars["String"]
  kind?: Maybe<Scalars["String"]>
  linkId?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
};


export type AuditLogsQuery = { __typename?: "Query", auditLogs: { __typename?: "AuditLogConnection", totalCount: number, edges: Array<{ __typename?: "AuditLogEdge", node: { __typename?: "AuditLog", id: string, message: string, createdAt: Date, kind: string, linkId?: Maybe<string>, subLinkId?: Maybe<string>, user: { __typename?: "User", auth0Id: string, name: string } } }>, pageInfo: { __typename?: "PageInfo", hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: Maybe<string>, endCursor?: Maybe<string> } } };

export type EmailTemplateQueryVariables = {
  id: Scalars["String"]
  tenantId: Scalars["String"]
};


export type EmailTemplateQuery = { __typename?: "Query", emailTemplate: { __typename?: "EmailTemplate", id: string, name: string, subject: string, template: string } };

export type EmailTemplatesQueryVariables = {
  tenantId: Scalars["String"]
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type EmailTemplatesQuery = { __typename?: "Query", emailTemplates: { __typename?: "EmailTemplateConnection", totalCount: number, edges: Array<{ __typename?: "EmailTemplateEdge", node: { __typename?: "EmailTemplate", id: string, name: string, subject: string, template: string } }>, pageInfo: { __typename?: "PageInfo", hasPreviousPage: boolean, startCursor?: Maybe<string>, hasNextPage: boolean, endCursor?: Maybe<string> } } };

export type FulfillmentQueryVariables = {
  id: Scalars["String"]
  tenantId: Scalars["String"]
};


export type FulfillmentQuery = { __typename?: "Query", fulfillment: { __typename?: "Fulfillment", id: string, status: string, email: string, lineItems: Array<{ __typename?: "FulfillmentLineItem", id: string, status: string, replacedById?: Maybe<string>, replacementReason?: Maybe<string>, licenseKey: { __typename?: "LicenseKey", id: string, status: string, product: { __typename?: "Product", id: string, name: string, status: string } } }> } };

export type FulfillmentsQueryVariables = {
  tenantId: Scalars["String"]
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
};


export type FulfillmentsQuery = { __typename?: "Query", fulfillments: { __typename?: "FulfillmentConnection", totalCount: number, edges: Array<{ __typename?: "FulfillmentEdge", node: { __typename?: "Fulfillment", id: string, status: string, email: string } }>, pageInfo: { __typename?: "PageInfo", hasPreviousPage: boolean, startCursor?: Maybe<string>, endCursor?: Maybe<string>, hasNextPage: boolean } } };

export type MeQueryVariables = {};


export type MeQuery = { __typename?: "Query", me: { __typename?: "Auth0UserView", email?: Maybe<string>, emailVerified?: Maybe<boolean>, username?: Maybe<string>, phoneNumber?: Maybe<string>, phoneVerified?: Maybe<boolean>, userId?: Maybe<string>, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, picture?: Maybe<string>, name?: Maybe<string>, nickname?: Maybe<string>, lastIp?: Maybe<string>, lastLogin?: Maybe<string>, lastPasswordReset?: Maybe<string>, loginsCount?: Maybe<number>, blocked?: Maybe<boolean>, givenName?: Maybe<string>, familyName?: Maybe<string> } };

export type MyTenantsQueryVariables = {};


export type MyTenantsQuery = { __typename?: "Query", myTenants: Array<{ __typename?: "Tenant", id: string, name: string, status: string }> };

export type ProductQueryVariables = {
  id: Scalars["String"]
  tenantId: Scalars["String"]
  includeLicenses: Scalars["Boolean"]
  after?: Maybe<Scalars["String"]>
  before?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type ProductQuery = { __typename?: "Query", product: { __typename?: "Product", id: string, name: string, status: string, availableLicenseCount: number, emailTemplate?: Maybe<{ __typename?: "EmailTemplate", id: string, name: string }>, licenses: { __typename?: "LicenseKeyConnection", totalCount: number, edges: Array<{ __typename?: "LicenseKeyEdge", node: { __typename?: "LicenseKey", id: string, status: string, createdAt: Date } }>, pageInfo: { __typename?: "PageInfo", hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: Maybe<string>, endCursor?: Maybe<string> } } } };

export type ProductsQueryVariables = {
  tenantId: Scalars["String"]
  before?: Maybe<Scalars["String"]>
  after?: Maybe<Scalars["String"]>
  first?: Maybe<Scalars["Int"]>
  last?: Maybe<Scalars["Int"]>
};


export type ProductsQuery = { __typename?: "Query", products: { __typename?: "ProductConnection", totalCount: number, edges: Array<{ __typename?: "ProductEdge", node: { __typename?: "Product", id: string, name: string, availableLicenseCount: number, status: string, createdAt: Date, emailTemplate?: Maybe<{ __typename?: "EmailTemplate", id: string, name: string }> } }>, pageInfo: { __typename?: "PageInfo", hasPreviousPage: boolean, startCursor?: Maybe<string>, hasNextPage: boolean, endCursor?: Maybe<string> } } };

export type TenantQueryVariables = {
  tenantId: Scalars["String"]
};


export type TenantQuery = { __typename?: "Query", tenant: { __typename?: "Tenant", id: string, name: string, status: string, keyId?: Maybe<string>, createdAt: Date, sendGridConfig?: Maybe<{ __typename?: "SendGridConfig", from: string }> } };

export type RemoveTenantSendGridConfigMutationVariables = {
  tenantId: Scalars["String"]
};


export type RemoveTenantSendGridConfigMutation = { __typename?: "Mutation", removeTenantSendGridConfig: { __typename?: "Tenant", id: string, name: string, status: string, keyId?: Maybe<string>, createdAt: Date, sendGridConfig?: Maybe<{ __typename?: "SendGridConfig", from: string }> } };

export type ReplaceLineItemMutationVariables = {
  fulfillmentId: Scalars["String"]
  lineItemId: Scalars["String"]
  reason: Scalars["String"]
  tenantId: Scalars["String"]
};


export type ReplaceLineItemMutation = { __typename?: "Mutation", replaceLicenseKey: { __typename?: "Fulfillment", id: string, status: string, email: string, lineItems: Array<{ __typename?: "FulfillmentLineItem", id: string, status: string, replacedById?: Maybe<string>, replacementReason?: Maybe<string>, licenseKey: { __typename?: "LicenseKey", id: string, status: string, product: { __typename?: "Product", id: string, name: string } } }> } };

export type RevealKeyQueryVariables = {
  fulfillmentId: Scalars["String"]
  lineItemId: Scalars["String"]
  tenantId: Scalars["String"]
};


export type RevealKeyQuery = { __typename?: "Query", revealKey: string };

export type SendPreviewEmailQueryVariables = {
  id: Scalars["String"]
  productId: Scalars["String"]
  to: Scalars["String"]
  tenantId: Scalars["String"]
};


export type SendPreviewEmailQuery = { __typename?: "Query", sendPreviewEmail: boolean };

export type UpdateEmailTemplateMutationVariables = {
  id: Scalars["String"]
  tenantId: Scalars["String"]
  input: EmailTemplateInput
};


export type UpdateEmailTemplateMutation = { __typename?: "Mutation", updateEmailTemplate: { __typename?: "EmailTemplate", id: string, name: string, subject: string, template: string } };

export type UpdateProductMutationVariables = {
  id: Scalars["String"]
  tenantId: Scalars["String"]
  input: ProductInput
};


export type UpdateProductMutation = { __typename?: "Mutation", updateProduct: { __typename?: "Product", id: string, name: string, createdAt: Date } };

export type UpdateTenantSendGridConfigMutationVariables = {
  tenantId: Scalars["String"]
  from: Scalars["String"]
  apiKey?: Maybe<Scalars["String"]>
};


export type UpdateTenantSendGridConfigMutation = { __typename?: "Mutation", updateTenantSendGridConfig: { __typename?: "Tenant", id: string } };

export type UploadLicensesMutationVariables = {
  productId: Scalars["String"]
  tenantId: Scalars["String"]
  csv: Scalars["Upload"]
};


export type UploadLicensesMutation = { __typename?: "Mutation", uploadLicenses: boolean };


export const CreateEmailTemplateDocument = gql`
    mutation createEmailTemplate($tenantId: String!, $input: EmailTemplateInput!) {
  createEmailTemplate(tenantId: $tenantId, input: $input) {
    id
    name
    subject
    template
  }
}
    `
export type CreateEmailTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>;

/**
 * __useCreateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useCreateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailTemplateMutation, { data, loading, error }] = useCreateEmailTemplateMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>(CreateEmailTemplateDocument, baseOptions)
      }
export type CreateEmailTemplateMutationHookResult = ReturnType<typeof useCreateEmailTemplateMutation>;
export type CreateEmailTemplateMutationResult = ApolloReactCommon.MutationResult<CreateEmailTemplateMutation>;
export type CreateEmailTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>;
export const CreateFulfillmentDocument = gql`
    mutation createFulfillment($input: FulfillmentInput!, $tenantId: String!) {
  createFulfillment(input: $input, tenantId: $tenantId) {
    id
    status
    email
    lineItems {
      id
      licenseKey {
        id
        status
        product {
          id
          name
          status
        }
      }
    }
  }
}
    `
export type CreateFulfillmentMutationFn = ApolloReactCommon.MutationFunction<CreateFulfillmentMutation, CreateFulfillmentMutationVariables>;

/**
 * __useCreateFulfillmentMutation__
 *
 * To run a mutation, you first call `useCreateFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFulfillmentMutation, { data, loading, error }] = useCreateFulfillmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCreateFulfillmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFulfillmentMutation, CreateFulfillmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFulfillmentMutation, CreateFulfillmentMutationVariables>(CreateFulfillmentDocument, baseOptions)
      }
export type CreateFulfillmentMutationHookResult = ReturnType<typeof useCreateFulfillmentMutation>;
export type CreateFulfillmentMutationResult = ApolloReactCommon.MutationResult<CreateFulfillmentMutation>;
export type CreateFulfillmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFulfillmentMutation, CreateFulfillmentMutationVariables>;
export const CreateLicenseDocument = gql`
    mutation createLicense($keyData: String!, $productId: String!, $tenantId: String!) {
  createLicense(input: {productId: $productId, keyData: $keyData}, tenantId: $tenantId) {
    id
    status
    owner {
      auth0Id
      name
    }
    product {
      id
      name
      status
      createdAt
    }
    createdAt
  }
}
    `
export type CreateLicenseMutationFn = ApolloReactCommon.MutationFunction<CreateLicenseMutation, CreateLicenseMutationVariables>;

/**
 * __useCreateLicenseMutation__
 *
 * To run a mutation, you first call `useCreateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseMutation, { data, loading, error }] = useCreateLicenseMutation({
 *   variables: {
 *      keyData: // value for 'keyData'
 *      productId: // value for 'productId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCreateLicenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLicenseMutation, CreateLicenseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLicenseMutation, CreateLicenseMutationVariables>(CreateLicenseDocument, baseOptions)
      }
export type CreateLicenseMutationHookResult = ReturnType<typeof useCreateLicenseMutation>;
export type CreateLicenseMutationResult = ApolloReactCommon.MutationResult<CreateLicenseMutation>;
export type CreateLicenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLicenseMutation, CreateLicenseMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($input: ProductInput!, $tenantId: String!) {
  createProduct(input: $input, tenantId: $tenantId) {
    id
    name
    createdAt
  }
}
    `
export type CreateProductMutationFn = ApolloReactCommon.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions)
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = ApolloReactCommon.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const DeleteEmailTemplateDocument = gql`
    mutation deleteEmailTemplate($id: String!, $tenantId: String!) {
  deleteEmailTemplate(id: $id, tenantId: $tenantId) {
    deletedId
    deletedType
  }
}
    `
export type DeleteEmailTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables>;

/**
 * __useDeleteEmailTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailTemplateMutation, { data, loading, error }] = useDeleteEmailTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useDeleteEmailTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables>(DeleteEmailTemplateDocument, baseOptions)
      }
export type DeleteEmailTemplateMutationHookResult = ReturnType<typeof useDeleteEmailTemplateMutation>;
export type DeleteEmailTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteEmailTemplateMutation>;
export type DeleteEmailTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables>;
export const AuditLogsDocument = gql`
    query auditLogs($tenantId: String!, $kind: String, $linkId: String, $first: Int, $last: Int, $before: String, $after: String) {
  auditLogs(tenantId: $tenantId, kind: $kind, linkId: $linkId, first: $first, last: $last, before: $before, after: $after) {
    totalCount
    edges {
      node {
        id
        message
        createdAt
        kind
        linkId
        subLinkId
        user {
          auth0Id
          name
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `

/**
 * __useAuditLogsQuery__
 *
 * To run a query within a React component, call `useAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      kind: // value for 'kind'
 *      linkId: // value for 'linkId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAuditLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, baseOptions)
      }
export function useAuditLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, baseOptions)
        }
export type AuditLogsQueryHookResult = ReturnType<typeof useAuditLogsQuery>;
export type AuditLogsLazyQueryHookResult = ReturnType<typeof useAuditLogsLazyQuery>;
export type AuditLogsQueryResult = ApolloReactCommon.QueryResult<AuditLogsQuery, AuditLogsQueryVariables>;
export const EmailTemplateDocument = gql`
    query emailTemplate($id: String!, $tenantId: String!) {
  emailTemplate(id: $id, tenantId: $tenantId) {
    id
    name
    subject
    template
  }
}
    `

/**
 * __useEmailTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useEmailTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmailTemplateQuery, EmailTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(EmailTemplateDocument, baseOptions)
      }
export function useEmailTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailTemplateQuery, EmailTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(EmailTemplateDocument, baseOptions)
        }
export type EmailTemplateQueryHookResult = ReturnType<typeof useEmailTemplateQuery>;
export type EmailTemplateLazyQueryHookResult = ReturnType<typeof useEmailTemplateLazyQuery>;
export type EmailTemplateQueryResult = ApolloReactCommon.QueryResult<EmailTemplateQuery, EmailTemplateQueryVariables>;
export const EmailTemplatesDocument = gql`
    query emailTemplates($tenantId: String!, $before: String, $after: String, $first: Int, $last: Int) {
  emailTemplates(tenantId: $tenantId, before: $before, after: $after, first: $first, last: $last) {
    edges {
      node {
        id
        name
        subject
        template
      }
    }
    pageInfo {
      hasPreviousPage
      startCursor
      hasNextPage
      endCursor
    }
    totalCount
  }
}
    `

/**
 * __useEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplatesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useEmailTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmailTemplatesQuery, EmailTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<EmailTemplatesQuery, EmailTemplatesQueryVariables>(EmailTemplatesDocument, baseOptions)
      }
export function useEmailTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailTemplatesQuery, EmailTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmailTemplatesQuery, EmailTemplatesQueryVariables>(EmailTemplatesDocument, baseOptions)
        }
export type EmailTemplatesQueryHookResult = ReturnType<typeof useEmailTemplatesQuery>;
export type EmailTemplatesLazyQueryHookResult = ReturnType<typeof useEmailTemplatesLazyQuery>;
export type EmailTemplatesQueryResult = ApolloReactCommon.QueryResult<EmailTemplatesQuery, EmailTemplatesQueryVariables>;
export const FulfillmentDocument = gql`
    query fulfillment($id: String!, $tenantId: String!) {
  fulfillment(id: $id, tenantId: $tenantId) {
    id
    status
    email
    lineItems {
      id
      status
      replacedById
      replacementReason
      licenseKey {
        id
        status
        product {
          id
          name
          status
        }
      }
    }
  }
}
    `

/**
 * __useFulfillmentQuery__
 *
 * To run a query within a React component, call `useFulfillmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfillmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfillmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useFulfillmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FulfillmentQuery, FulfillmentQueryVariables>) {
        return ApolloReactHooks.useQuery<FulfillmentQuery, FulfillmentQueryVariables>(FulfillmentDocument, baseOptions)
      }
export function useFulfillmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FulfillmentQuery, FulfillmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FulfillmentQuery, FulfillmentQueryVariables>(FulfillmentDocument, baseOptions)
        }
export type FulfillmentQueryHookResult = ReturnType<typeof useFulfillmentQuery>;
export type FulfillmentLazyQueryHookResult = ReturnType<typeof useFulfillmentLazyQuery>;
export type FulfillmentQueryResult = ApolloReactCommon.QueryResult<FulfillmentQuery, FulfillmentQueryVariables>;
export const FulfillmentsDocument = gql`
    query fulfillments($tenantId: String!, $first: Int, $last: Int, $before: String, $after: String) {
  fulfillments(tenantId: $tenantId, first: $first, last: $last, before: $before, after: $after) {
    totalCount
    edges {
      node {
        id
        status
        email
      }
    }
    pageInfo {
      hasPreviousPage
      startCursor
      endCursor
      hasNextPage
    }
  }
}
    `

/**
 * __useFulfillmentsQuery__
 *
 * To run a query within a React component, call `useFulfillmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfillmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfillmentsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFulfillmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FulfillmentsQuery, FulfillmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<FulfillmentsQuery, FulfillmentsQueryVariables>(FulfillmentsDocument, baseOptions)
      }
export function useFulfillmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FulfillmentsQuery, FulfillmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FulfillmentsQuery, FulfillmentsQueryVariables>(FulfillmentsDocument, baseOptions)
        }
export type FulfillmentsQueryHookResult = ReturnType<typeof useFulfillmentsQuery>;
export type FulfillmentsLazyQueryHookResult = ReturnType<typeof useFulfillmentsLazyQuery>;
export type FulfillmentsQueryResult = ApolloReactCommon.QueryResult<FulfillmentsQuery, FulfillmentsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    email
    emailVerified
    username
    phoneNumber
    phoneVerified
    userId
    createdAt
    updatedAt
    picture
    name
    nickname
    lastIp
    lastLogin
    lastPasswordReset
    loginsCount
    blocked
    givenName
    familyName
  }
}
    `

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions)
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions)
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const MyTenantsDocument = gql`
    query myTenants {
  myTenants {
    id
    name
    status
  }
}
    `

/**
 * __useMyTenantsQuery__
 *
 * To run a query within a React component, call `useMyTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTenantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyTenantsQuery, MyTenantsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyTenantsQuery, MyTenantsQueryVariables>(MyTenantsDocument, baseOptions)
      }
export function useMyTenantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyTenantsQuery, MyTenantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyTenantsQuery, MyTenantsQueryVariables>(MyTenantsDocument, baseOptions)
        }
export type MyTenantsQueryHookResult = ReturnType<typeof useMyTenantsQuery>;
export type MyTenantsLazyQueryHookResult = ReturnType<typeof useMyTenantsLazyQuery>;
export type MyTenantsQueryResult = ApolloReactCommon.QueryResult<MyTenantsQuery, MyTenantsQueryVariables>;
export const ProductDocument = gql`
    query product($id: String!, $tenantId: String!, $includeLicenses: Boolean!, $after: String, $before: String, $first: Int, $last: Int) {
  product(id: $id, tenantId: $tenantId) {
    id
    name
    status
    emailTemplate {
      id
      name
    }
    availableLicenseCount
    licenses(after: $after, before: $before, first: $first, last: $last) @include(if: $includeLicenses) {
      totalCount
      edges {
        node {
          id
          status
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
}
    `

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *      includeLicenses: // value for 'includeLicenses'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions)
      }
export function useProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions)
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = ApolloReactCommon.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductsDocument = gql`
    query products($tenantId: String!, $before: String, $after: String, $first: Int, $last: Int) {
  products(tenantId: $tenantId, before: $before, after: $after, first: $first, last: $last) {
    edges {
      node {
        id
        name
        emailTemplate {
          id
          name
        }
        availableLicenseCount
        status
        createdAt
      }
    }
    pageInfo {
      hasPreviousPage
      startCursor
      hasNextPage
      endCursor
    }
    totalCount
  }
}
    `

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions)
      }
export function useProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions)
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const TenantDocument = gql`
    query tenant($tenantId: String!) {
  tenant(tenantId: $tenantId) {
    id
    name
    status
    keyId
    createdAt
    sendGridConfig {
      from
    }
  }
}
    `

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
        return ApolloReactHooks.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, baseOptions)
      }
export function useTenantLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, baseOptions)
        }
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>;
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>;
export type TenantQueryResult = ApolloReactCommon.QueryResult<TenantQuery, TenantQueryVariables>;
export const RemoveTenantSendGridConfigDocument = gql`
    mutation removeTenantSendGridConfig($tenantId: String!) {
  removeTenantSendGridConfig(tenantId: $tenantId) {
    id
    name
    status
    keyId
    createdAt
    sendGridConfig {
      from
    }
  }
}
    `
export type RemoveTenantSendGridConfigMutationFn = ApolloReactCommon.MutationFunction<RemoveTenantSendGridConfigMutation, RemoveTenantSendGridConfigMutationVariables>;

/**
 * __useRemoveTenantSendGridConfigMutation__
 *
 * To run a mutation, you first call `useRemoveTenantSendGridConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTenantSendGridConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTenantSendGridConfigMutation, { data, loading, error }] = useRemoveTenantSendGridConfigMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useRemoveTenantSendGridConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTenantSendGridConfigMutation, RemoveTenantSendGridConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTenantSendGridConfigMutation, RemoveTenantSendGridConfigMutationVariables>(RemoveTenantSendGridConfigDocument, baseOptions)
      }
export type RemoveTenantSendGridConfigMutationHookResult = ReturnType<typeof useRemoveTenantSendGridConfigMutation>;
export type RemoveTenantSendGridConfigMutationResult = ApolloReactCommon.MutationResult<RemoveTenantSendGridConfigMutation>;
export type RemoveTenantSendGridConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTenantSendGridConfigMutation, RemoveTenantSendGridConfigMutationVariables>;
export const ReplaceLineItemDocument = gql`
    mutation replaceLineItem($fulfillmentId: String!, $lineItemId: String!, $reason: String!, $tenantId: String!) {
  replaceLicenseKey(fulfillmentId: $fulfillmentId, lineItemId: $lineItemId, reason: $reason, tenantId: $tenantId) {
    id
    status
    email
    lineItems {
      id
      status
      licenseKey {
        id
        status
        product {
          id
          name
        }
      }
      replacedById
      replacementReason
    }
  }
}
    `
export type ReplaceLineItemMutationFn = ApolloReactCommon.MutationFunction<ReplaceLineItemMutation, ReplaceLineItemMutationVariables>;

/**
 * __useReplaceLineItemMutation__
 *
 * To run a mutation, you first call `useReplaceLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceLineItemMutation, { data, loading, error }] = useReplaceLineItemMutation({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *      lineItemId: // value for 'lineItemId'
 *      reason: // value for 'reason'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useReplaceLineItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReplaceLineItemMutation, ReplaceLineItemMutationVariables>) {
        return ApolloReactHooks.useMutation<ReplaceLineItemMutation, ReplaceLineItemMutationVariables>(ReplaceLineItemDocument, baseOptions)
      }
export type ReplaceLineItemMutationHookResult = ReturnType<typeof useReplaceLineItemMutation>;
export type ReplaceLineItemMutationResult = ApolloReactCommon.MutationResult<ReplaceLineItemMutation>;
export type ReplaceLineItemMutationOptions = ApolloReactCommon.BaseMutationOptions<ReplaceLineItemMutation, ReplaceLineItemMutationVariables>;
export const RevealKeyDocument = gql`
    query revealKey($fulfillmentId: String!, $lineItemId: String!, $tenantId: String!) {
  revealKey(fulfillmentId: $fulfillmentId, lineItemId: $lineItemId, tenantId: $tenantId)
}
    `

/**
 * __useRevealKeyQuery__
 *
 * To run a query within a React component, call `useRevealKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevealKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevealKeyQuery({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *      lineItemId: // value for 'lineItemId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useRevealKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RevealKeyQuery, RevealKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<RevealKeyQuery, RevealKeyQueryVariables>(RevealKeyDocument, baseOptions)
      }
export function useRevealKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RevealKeyQuery, RevealKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RevealKeyQuery, RevealKeyQueryVariables>(RevealKeyDocument, baseOptions)
        }
export type RevealKeyQueryHookResult = ReturnType<typeof useRevealKeyQuery>;
export type RevealKeyLazyQueryHookResult = ReturnType<typeof useRevealKeyLazyQuery>;
export type RevealKeyQueryResult = ApolloReactCommon.QueryResult<RevealKeyQuery, RevealKeyQueryVariables>;
export const SendPreviewEmailDocument = gql`
    query sendPreviewEmail($id: String!, $productId: String!, $to: String!, $tenantId: String!) {
  sendPreviewEmail(id: $id, productId: $productId, to: $to, tenantId: $tenantId)
}
    `

/**
 * __useSendPreviewEmailQuery__
 *
 * To run a query within a React component, call `useSendPreviewEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendPreviewEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPreviewEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      to: // value for 'to'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useSendPreviewEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SendPreviewEmailQuery, SendPreviewEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<SendPreviewEmailQuery, SendPreviewEmailQueryVariables>(SendPreviewEmailDocument, baseOptions)
      }
export function useSendPreviewEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendPreviewEmailQuery, SendPreviewEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SendPreviewEmailQuery, SendPreviewEmailQueryVariables>(SendPreviewEmailDocument, baseOptions)
        }
export type SendPreviewEmailQueryHookResult = ReturnType<typeof useSendPreviewEmailQuery>;
export type SendPreviewEmailLazyQueryHookResult = ReturnType<typeof useSendPreviewEmailLazyQuery>;
export type SendPreviewEmailQueryResult = ApolloReactCommon.QueryResult<SendPreviewEmailQuery, SendPreviewEmailQueryVariables>;
export const UpdateEmailTemplateDocument = gql`
    mutation updateEmailTemplate($id: String!, $tenantId: String!, $input: EmailTemplateInput!) {
  updateEmailTemplate(id: $id, tenantId: $tenantId, input: $input) {
    id
    name
    subject
    template
  }
}
    `
export type UpdateEmailTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>;

/**
 * __useUpdateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailTemplateMutation, { data, loading, error }] = useUpdateEmailTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>(UpdateEmailTemplateDocument, baseOptions)
      }
export type UpdateEmailTemplateMutationHookResult = ReturnType<typeof useUpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: String!, $tenantId: String!, $input: ProductInput!) {
  updateProduct(id: $id, input: $input, tenantId: $tenantId) {
    id
    name
    createdAt
  }
}
    `
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions)
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateTenantSendGridConfigDocument = gql`
    mutation updateTenantSendGridConfig($tenantId: String!, $from: String!, $apiKey: String) {
  updateTenantSendGridConfig(tenantId: $tenantId, from: $from, apiKey: $apiKey) {
    id
  }
}
    `
export type UpdateTenantSendGridConfigMutationFn = ApolloReactCommon.MutationFunction<UpdateTenantSendGridConfigMutation, UpdateTenantSendGridConfigMutationVariables>;

/**
 * __useUpdateTenantSendGridConfigMutation__
 *
 * To run a mutation, you first call `useUpdateTenantSendGridConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantSendGridConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantSendGridConfigMutation, { data, loading, error }] = useUpdateTenantSendGridConfigMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      from: // value for 'from'
 *      apiKey: // value for 'apiKey'
 *   },
 * });
 */
export function useUpdateTenantSendGridConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTenantSendGridConfigMutation, UpdateTenantSendGridConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTenantSendGridConfigMutation, UpdateTenantSendGridConfigMutationVariables>(UpdateTenantSendGridConfigDocument, baseOptions)
      }
export type UpdateTenantSendGridConfigMutationHookResult = ReturnType<typeof useUpdateTenantSendGridConfigMutation>;
export type UpdateTenantSendGridConfigMutationResult = ApolloReactCommon.MutationResult<UpdateTenantSendGridConfigMutation>;
export type UpdateTenantSendGridConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTenantSendGridConfigMutation, UpdateTenantSendGridConfigMutationVariables>;
export const UploadLicensesDocument = gql`
    mutation uploadLicenses($productId: String!, $tenantId: String!, $csv: Upload!) {
  uploadLicenses(productId: $productId, tenantId: $tenantId, csv: $csv)
}
    `
export type UploadLicensesMutationFn = ApolloReactCommon.MutationFunction<UploadLicensesMutation, UploadLicensesMutationVariables>;

/**
 * __useUploadLicensesMutation__
 *
 * To run a mutation, you first call `useUploadLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLicensesMutation, { data, loading, error }] = useUploadLicensesMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      tenantId: // value for 'tenantId'
 *      csv: // value for 'csv'
 *   },
 * });
 */
export function useUploadLicensesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadLicensesMutation, UploadLicensesMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadLicensesMutation, UploadLicensesMutationVariables>(UploadLicensesDocument, baseOptions)
      }
export type UploadLicensesMutationHookResult = ReturnType<typeof useUploadLicensesMutation>;
export type UploadLicensesMutationResult = ApolloReactCommon.MutationResult<UploadLicensesMutation>;
export type UploadLicensesMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadLicensesMutation, UploadLicensesMutationVariables>;