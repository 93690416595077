import React from "react"
import {Switch} from "react-router-dom"
import {ApiScopes, escapeRegExp} from "shared"

import { Activity } from "../../screens/activity/Activity"
import {Dashboard} from "../../screens/dashboard/Dashboard"
import { CreateEmailTemplate } from "../../screens/email-templates/CreateEmailTemplate"
import { EmailTemplate } from "../../screens/email-templates/EmailTemplate"
import { EmailTemplates } from "../../screens/email-templates/EmailTemplates"
import { CreateFulfillment } from "../../screens/fulfillment/CreateFulfillment"
import { Fulfillment } from "../../screens/fulfillment/Fulfillment"
import { FulfillmentActivity } from "../../screens/fulfillment/FulfillmentActivity"
import { Fulfillments } from "../../screens/fulfillment/Fulfillments"
import { AddLicense } from "../../screens/products/AddLicense"
import { CreateProduct } from "../../screens/products/CreateProduct"
import { Product } from "../../screens/products/Product"
import { ProductActivity } from "../../screens/products/ProductActivity"
import { Products } from "../../screens/products/Products"
import {Settings} from "../../screens/settings/Settings"
import {PrivateRoute, PrivateRouteProps} from "../basic/PrivateRoute"

export type RouteDefinition = {
  path: string
  permissions?: string[]
}

export const parseDynamicPath = (path: string, parameters: Record<string, string>) => {
  return Object.entries(parameters)
    .map(([key, value]) => ([escapeRegExp(key), value]))
    .reduce((temp, [key, value]) => {
      const regex = new RegExp(`\\b\\/:${key}\\b`, "gmis")
      return temp.replace(regex, `/${value}`)
    }, path)
}

export const RouteDefinitions: {[k: string]: RouteDefinition} = {
  products: {
    path: "/products",
    permissions: [ApiScopes.AdminProducts, ApiScopes.CreateProducts, ApiScopes.ReadProducts]
  },
  createProduct: {
    path: "/products/create",
    permissions: [ApiScopes.AdminProducts, ApiScopes.CreateProducts]
  },
  viewProduct: {
    path: "/products/:id",
    permissions: [ApiScopes.AdminProducts, ApiScopes.CreateProducts, ApiScopes.ReadProducts]
  },
  viewProductActivity: {
    path: "/products/:id/activity",
    permissions: [ApiScopes.AdminProducts]
  },
  createLicense: {
    path: "/products/:id/license",
    permissions: [ApiScopes.AdminLicenses, ApiScopes.CreateLicenses]
  },
  fulfillments: {
    path: "/fulfillments",
    permissions: [ApiScopes.AdminFulfillments,ApiScopes.CreateFulfillments, ApiScopes.ReadFulfillments, ApiScopes.RevealFulfillments]
  },
  createFulfillment: {
    path: "/fulfillments/create",
    permissions: [ApiScopes.AdminFulfillments,ApiScopes.CreateFulfillments, ApiScopes.RevealFulfillments]
  },
  viewFulfillment: {
    path: "/fulfillments/:id",
    permissions: [ApiScopes.AdminFulfillments,ApiScopes.CreateFulfillments, ApiScopes.ReadFulfillments, ApiScopes.RevealFulfillments]
  },
  viewFulfillmentActivity: {
    path: "/fulfillments/:id/activity",
    permissions: [ApiScopes.AdminFulfillments]
  },
  emailTemplates: {
    path: "/email-templates",
    permissions: [ApiScopes.CreateTemplates, ApiScopes.ReadTemplates, ApiScopes.AdminTemplates]
  },
  createEmailTemplate: {
    path: "/email-templates/create",
    permissions: [ApiScopes.CreateTemplates, ApiScopes.AdminTemplates]
  },
  viewEmailTemplate: {
    path: "/email-templates/:id",
    permissions: [ApiScopes.AdminTemplates, ApiScopes.CreateTemplates, ApiScopes.ReadTemplates]
  },
  activity: {
    path: "/activity"
  },
  settings: {
    path: "/settings"
  }
}

const privateRouteFromDefinition = (definition: RouteDefinition, props: Omit<PrivateRouteProps, "path" | "permissions">) => {
  const {path, permissions} = definition
  return <PrivateRoute path={path} permissions={permissions} {...props} />
}

export const Routes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path="/" component={Dashboard} exact />
      {privateRouteFromDefinition(RouteDefinitions.products, {component: Products, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.createProduct, {component: CreateProduct, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.viewProduct, {component: Product, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.viewProductActivity, {component: ProductActivity, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.createLicense, {component: AddLicense, exact: true})}
      
      {privateRouteFromDefinition(RouteDefinitions.fulfillments, {component: Fulfillments, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.createFulfillment, {component: CreateFulfillment, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.viewFulfillment, {component: Fulfillment, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.viewFulfillmentActivity, {component: FulfillmentActivity, exact: true})}

      {privateRouteFromDefinition(RouteDefinitions.emailTemplates, {component: EmailTemplates, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.createEmailTemplate, {component: CreateEmailTemplate, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.viewEmailTemplate, {component: EmailTemplate, exact: true})}

      {privateRouteFromDefinition(RouteDefinitions.activity, {component: Activity, exact: true})}
      {privateRouteFromDefinition(RouteDefinitions.settings, {component: Settings, exact: true})}
    </Switch>
  )
}