import { ApolloProvider } from "@apollo/react-hooks"
import env from "@beam-australia/react-env"
import {InMemoryCache} from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import {createUploadLink} from "apollo-upload-client"
import React, { useMemo, useState } from "react"

import { useAuth0 } from "./Auth0Provider"

type Context = {
  loading: boolean
}

export const ApolloStatusContext = React.createContext<Context | null>(null)
export const useApolloStatus = () => React.useContext(ApolloStatusContext)!

export const AuthenticatedApolloProvider: React.FC = ({children}) => {
  const {token} = useAuth0()
  const [loading, setLoading] = useState(false)

  const client = useMemo(() => {
    return new ApolloClient({
      link: createUploadLink({
        uri: env("STOPLOSS_API_URL"),
        headers: {
          authorization: token ? `Bearer ${token}` : ""
        },
        fetch: async (url: RequestInfo, init?: RequestInit) => {
          setLoading(true)
          const response = await fetch(url, init)
          setLoading(false)
          return response
        }
      }),
      cache: new InMemoryCache(),
      connectToDevTools: true
    })
  }, [token])

  return (
    <ApolloProvider client={client}>
      <ApolloStatusContext.Provider value={{loading}}>
        {children}
      </ApolloStatusContext.Provider>
    </ApolloProvider>
  )
}
