import { MenuItem,Select, SelectProps, Typography } from "@material-ui/core"
import {makeStyles} from "@material-ui/core"
import React from "react"

import { useTenant } from "../../providers/TenantProvider"

const useStyles = makeStyles((theme) => ({
  select: {
    marginRight: theme.spacing(2)
  }
}))

export type TenantSelectorProps = SelectProps

export const TenantSelector: React.FC<TenantSelectorProps> = (props) => {
  const classes = useStyles()
  const {tenants, activeTenant, setActiveTenant} = useTenant()

  if (tenants.length === 1) {
    return (
      <Typography color="textSecondary">{activeTenant.name}</Typography>
    )
  }

  return (
    <Select
      className={classes.select}
      value={activeTenant.id}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
        const tenantId = event.target.value as string
        setActiveTenant(tenants.find(t => t.id === tenantId)!)
      }}
      {...props}
    >
      {
        tenants.map(t => (<MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>))
      }
    </Select>
  )
}