import {ClickAwayListener, Grow,MenuList, Paper, Popper} from "@material-ui/core"
import {ReferenceObject} from "popper.js"
import React, {MutableRefObject} from "react"

export type DropdownMenuProps = {
  anchorRef: MutableRefObject<null | ReferenceObject>
  open: boolean
  onClose: () => void
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({anchorRef, open, onClose, children}) => {
  return (
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={event => {
                if (event.key === "Tab") {
                  event.preventDefault()
                  onClose()
                }
              }}>
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}