import React from "react"

import { usePermissions } from "../../hooks/use-permissions"

export type PermissionGateProps = {
  permissions?: string[]
  unauthorizedComponent?: React.ReactNode
}

export const PermissionGate: React.FC<PermissionGateProps> = ({permissions, unauthorizedComponent, children}) => {
  const hasPermission = usePermissions(permissions)

  return (
    <>
      {hasPermission
        ? children
        : unauthorizedComponent
      }
    </>
  )
}
