import { Grid } from "@material-ui/core"
import React from "react"
import { useParams } from "react-router-dom"

import { ActivityList } from "../../components/activity/ActivityList"
import { ScreenHeader } from "../../components/basic/ScreenHeader"
import { parseDynamicPath,RouteDefinitions } from "../../components/structure/Routes"

export const FulfillmentActivity: React.FC = () => {
  const {id} = useParams<{id: string}>()

  return (
    <Grid container spacing={3}>
      <ScreenHeader
        title="Activity"
        breadcrumbs={[
          { href: RouteDefinitions.fulfillments.path, content: "Fulfillments"},
          { href: parseDynamicPath(RouteDefinitions.viewFulfillment.path, {id}), content: "Fulfillment"}
        ]}
      />
      <ActivityList kind="Fulfillment" linkId={id}/>
    </Grid>
  )
}